import React from "react";
import "./css/Landing.css";
import { Helmet } from 'react-helmet';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import moscot from "./moscot.png";
import { lazy } from 'react';

import loadable from '@loadable/component'
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import AnyQuestion from "../Components/AnyQuestion/AnyQuestion";
import PluginWorks from "../Components/PluginWorks/PluginWorks";
import FeatureTab from "../Components/FeatureTab/FeatureTab";
import HeroBanner from "../Components/HeroBanner/HeroBanner";
import JoinCarriers from "../Components/JoinCarriers/JoinCarriers";
import ZeroCommission from "../Components/ZeroCommission/ZeroCommission";
import CustomCarrier from "../Components/CustomCarrier/CustomCarrier";
import FeatureBenefits from "../Components/FeatureBenefits/FeatureBenefits";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const TITLE = 'Home';  

const Landing = () => {
  const [verified, setverified] = useState(true);
  const [emailnews, setemailnews] = useState("");
  const [show, setshow] = useState("1");
  const [showtoast, setShowtoast] = useState(false);
  const [toastcontent, settoastcontent] = useState();
  const [Tracking_Id, setTracking_Id] = useState("");
  const [snack, setSnack] = useState({
    open: false,
    severity: "success",
    autoHideDuration: 6000,
    text: "",
    vertical: "top",
    horizontal: "right",
  });





  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnack({ ...snack, open: false });
  };





  return (
    <>
   <Helmet>
        <title>Hajex Bolt | { TITLE } </title>
          <meta name="theme-color" content="#000000" />
          <meta name="description" content="Simplify store integration with our free plugins for Woocommerce and Shopify - connect effortlessly in just a few clicks!,Track your packages/shipments using carrier provided tracking codes or use our unique tracking codes to ease and provide better tracking experience to your customers.,Our plugins come with 'Free Forever' plan to help Small Businesses."/>
          <meta name="keywords" content="Custom Carrier Integration,Zero Commission - Seamless Integration, Carriers World Wide, Hajex Canada"/>
          <meta name="author" content="Hajex"/>
          <meta property="og:title" content="Your Powerful Ecommerce Shipping Solution" />
          <meta property="og:description" content="Simplify store integration with our free plugins for Woocommerce and Shopify - connect effortlessly in just a few clicks!,Track your packages/shipments using carrier provided tracking codes or use our unique tracking codes to ease and provide better tracking experience to your customers.,Our plugins come with 'Free Forever' plan to help Small Businesses." />
          <meta property="og:image" content="https://hajexbolt.com/static/media/storeconnectimg.8ae073090b0808c0fa50.png" />
          <meta property="og:url" content="https://hajexbolt.com/" />
          <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
      </Helmet>
      <Snackbar
        open={snack.open}
        autoHideDuration={snack.autoHideDuration}
        onClose={handleClose}
        anchorOrigin={{
          vertical: snack.vertical,
          horizontal: snack.horizontal,
        }}
      >
        <Alert
          onClose={handleClose}
          severity={snack.severity}
          sx={{ width: "100%" }}
        >
          {snack.text}
        </Alert>
      </Snackbar>
 
      <Header />
      <HeroBanner/>
      <JoinCarriers/>
      <ZeroCommission/>
      <CustomCarrier/>
      <FeatureTab/>
      <PluginWorks/>
      <FeatureBenefits/>
      <AnyQuestion snack={snack} setSnack={setSnack} />
      <Footer snack={snack} setSnack={setSnack} />


      <ToastContainer position={"top-end"} className="p-3 mt-14">
        <Toast
          onClose={() => setShowtoast(false)}
          show={showtoast}
          delay={2000}
          autohide
          className="ml-16"
          bg={"light"}
        >
          <Toast.Header>
            <img src={moscot} className="rounded me-2" alt="" width="50" />
            <strong className="me-auto">Hajex Shipment</strong>
            <small>just now</small>
          </Toast.Header>
          <Toast.Body>{toastcontent}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default Landing;
