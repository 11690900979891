import React, { useState } from "react";
import logo1 from "../../Landingpage/images/logo 1.png";
import email from "../../Landingpage/images/email.png";
import phone from "../../Landingpage/images/phone.png";
import Vector0 from "../../Landingpage/images/Vector0.png";
import firrmarker from "../../Landingpage/images/fi-rr-marker.png";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import moscot from "./moscot.png";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import "./Footer.css";
import {
  BsYoutube,
  BsLinkedin,
  BsFacebook,
  BsTwitter,
  BsPinterest,
  BsShop,
  BsTruck,
} from "react-icons/bs";
import { Link } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Footer = () => {
  const [emailnews, setemailnews] = useState("");
  const [showtoast, setShowtoast] = useState(false);
  const [toastcontent, settoastcontent] = useState();
  const [snack, setSnack] = useState({
    open: false,
    severity: "success",
    autoHideDuration: 6000,
    text: "",
    vertical: "top",
    horizontal: "right",
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnack({ ...snack, open: false });
  };

  const handleNewsletter = async (event) => {
    event.preventDefault();
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}landing-page/newsletter`,
      data: {
        email: emailnews,
      },
    });
    // console.log(response);
    if (response.data.status === true) {
      // settoastcontent(response.data.message);
      // setShowtoast(true);
      setSnack({
        ...snack,
        open: true,
        severity: "success",
        text: response.data.message,
      });
    } else {
      // settoastcontent(response.data.message);
      // setShowtoast(true);
      setSnack({
        ...snack,
        open: true,
        severity: "error",
        text: response.data.message,
      });
    }
    setemailnews("");
  };
  return (
    <footer>
      <div className="newsletter__main_wrap">
        <Snackbar
          open={snack.open}
          autoHideDuration={snack.autoHideDuration}
          onClose={handleClose}
          anchorOrigin={{
            vertical: snack.vertical,
            horizontal: snack.horizontal,
          }}
        >
          <Alert
            onClose={handleClose}
            severity={snack.severity}
            sx={{ width: "100%" }}
          >
            {snack.text}
          </Alert>
        </Snackbar>

        <div className="newsletter__main_wrap">
          <div className="signup-banner">
            <Form onSubmit={handleNewsletter}>
              <h2 className="pe-4">Sign up for our Newsletter</h2>
              <div className="sigup-email">
                <LazyLoadImage
                  width="24"
                  height="24"
                  src={Vector0}
                  className="img-fluid ps-2"
                  style={{ display: "inline" }}
                />
                <input
                  type="email"
                  className="input"
                  placeholder="Email"
                  name="emailetter"
                  value={emailnews}
                  onChange={(event) => setemailnews(event.target.value)}
                  required
                />
                <button className="btn btn-primary" id="newsbtn" type="submit">
                  Subscribe
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div className="footersection__nav_main_wrap">
        <div className="footersection__nav_col_main">
          <div className="footersection__logo">
            <LazyLoadImage
              width="240"
              height="33"
              src={logo1}
              className="img-fluid"
            />
            <p className="py-3">
              Simplify and save with our all included shipping service
            </p>
          </div>
          <div className="footersection__nav_list_wrap">
            <h2>Company</h2>
            <ul className="footersection__nav_list">
              <li>
                <LinkContainer to="/">
                  <Nav.Link>Home</Nav.Link>
                </LinkContainer>
              </li>
              <li>
                <LinkContainer to="/about">
                  <Nav.Link>About us</Nav.Link>
                </LinkContainer>
              </li>
              {/* <li>
                <Nav.Link href="https://blog.hajexbolt.com">Blogs</Nav.Link>
              </li> */}
              <li>
                <LinkContainer to="/contact">
                  <Nav.Link>Contact</Nav.Link>
                </LinkContainer>
              </li>
            </ul>
          </div>
        </div>
        <div className="footersection__nav_col_main">
          <h2>Our Plugins</h2>
          <ul className="footersection__nav_list">
            <li>
              <Navbar.Brand href="/#zerocommission__section_wrap">
                Shopify
              </Navbar.Brand>
            </li>
            <li>
              <Navbar.Brand href="/#zerocommission__section_wrap">
                Woocommerce
              </Navbar.Brand>
            </li>
            <li>
              <Navbar.Brand href="/#zerocommission__section_wrap">
                Walmart
              </Navbar.Brand>
            </li>
            <li>
              <Navbar.Brand href="/#zerocommission__section_wrap">
                Ebay
              </Navbar.Brand>
            </li>
            <li>
              <Navbar.Brand href="/#zerocommission__section_wrap">
                Amazon
              </Navbar.Brand>
            </li>
            <li>
              <Navbar.Brand href="/#zerocommission__section_wrap">
                Bestbuy
              </Navbar.Brand>
            </li>
          </ul>
        </div>
        <div className="footersection__nav_col_main">
          <h2>Shipping Solutions</h2>
          <ul className="footersection__nav_list">
            <li>
              <Navbar.Brand href="https://ship.hajexbolt.com">
                Ship a package
              </Navbar.Brand>
            </li>
            <li>
              <Navbar.Brand href="https://ship.hajexbolt.com">
                Tracking
              </Navbar.Brand>
            </li>
            <li>
              <Navbar.Brand href="https://ship.hajexbolt.com">
                Invoices
              </Navbar.Brand>
            </li>
            <li>
              <Navbar.Brand href="https://ship.hajexbolt.com">
                Marketplaces
              </Navbar.Brand>
            </li>
            <li>
              <Navbar.Brand href="https://ship.hajexbolt.com">
                Pickups
              </Navbar.Brand>
            </li>
          </ul>
        </div>
        <div className="footersection__nav_col_main">
          <h2>For Shipper</h2>
          <ul className="footersection__nav_list">
            <li>
              <Navbar.Brand href="https://ship.hajexbolt.com/Register">
                Create a Shipper account
              </Navbar.Brand>
            </li>
            <li>
              <Navbar.Brand href="https://ship.hajexbolt.com/Register">
                {" "}
                Shipper FAQs
              </Navbar.Brand>
            </li>
          </ul>
          <h2>For Carriers</h2>
          <ul className="footersection__nav_list">
            <li>
              <Navbar.Brand href="https://ship.hajexbolt.com/Register">
                Create a Carriers account
              </Navbar.Brand>
            </li>
            <li>
              <Navbar.Brand href="https://ship.hajexbolt.com/Register">
                {" "}
                Carriers FAQs
              </Navbar.Brand>
            </li>
          </ul>
        </div>
      </div>
      <div className="getintouch__main_wrap">
        <div className="get-in-touch text-center">
          <h2 className="text-center">Get In Touch</h2>
          <a href="mailto:info@hajexbolt.com">
            <LazyLoadImage width="18" height="18" src={email} />
            info@hajexbolt.com
          </a>
          <a href="tel:+1 855 999 6659">
            <LazyLoadImage width="18" height="18" src={phone} />
            855 999 6659
          </a>
          <a href="https://maps.app.goo.gl/EHtcbSFK9vKxyA959" target="_blank">
            <LazyLoadImage width="16" height="16" src={firrmarker} />
            5887 Chemin St François, Saint-Laurent, QC H4S1B6, Canada
          </a>
        </div>
      </div>
      <div className="socialmedia__wrap">
        <div className="socialmedia__wrap_col">
          <a
            href="https://linkedin.com/company/hajex-bolt"
            aria-label="Social Media Link Linkdln"
            aria-labelledby="Social Media Link Linkdln"
            title="Social Media Link Linkdln"
          >
            <BsLinkedin className="ins" />
          </a>
          <a
            href="https://www.facebook.com/people/HAJEX-Bolt/100088507484382/"
            aria-label="Social Media Link Facebook"
            aria-labelledby="Social Media Link Facebook"
            title="Social Media Link Facebook"
          >
            <BsFacebook className="ins" />
          </a>
        </div>
      </div>
      <div className="footercopypolicy__wrap">
        <div className="footercopypolicy__copylist">
          <p>© 2024 copyright HAJEX. All rights reserved.</p>
        </div>
        <div className="footercopypolicy__policypages">
          <Link to="/terms"> Terms and Condition</Link>
          <Link to="/policy"> Privacy Policy</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
