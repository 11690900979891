import React, { useState } from "react";
import "./AnyQuestion.css";
import user from "./images/user.png";
import email from "./images/email.png";
import formphone from "./images/form-phone.png";
import company from "./images/company.png";
import feedback from "./images/feedback.png";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import loader from "./images/est-loader.gif";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const AnyQuestion = ({ snack, setSnack }) => {
  const [verified, setverified] = useState(true);
  const [showtoast, setShowtoast] = useState(false);
  const [toastcontent, settoastcontent] = useState();
  const [submitting, setSubmitting] = useState(false); 
  const [Question, setQuestion] = useState([
    {
      name: "",
      email: "",
      phoneno: "",
      companyname: "",
      question: "",
    },
  ]);

  const handleQuestions = (e) => {
    const slist = [...Question];
    const { name, value } = e.target;
    slist[0][name] = value;
    setQuestion(slist);
  };

  function onChange(value) {
    // console.log("Captcha value:", value);
    setverified(true);
  }

  const handleRegister = async (event) => {
    event.preventDefault();
    setSubmitting(true); 
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}landing-page/send-querry`,
      data: {
        name: Question[0]["name"],
        email: Question[0]["email"],
        phone: Question[0]["phoneno"],
        company: Question[0]["companyname"],
        question: Question[0]["question"],
      },
    });
    if (response.data.status === true) {
      // settoastcontent(response.data.message);
      // setShowtoast(true);
      setSnack({
        ...snack,
        open: true,
        severity: "success",
        text: response.data.message,
      });
      setQuestion([
        {
          name: "",
          email: "",
          phoneno: "",
          companyname: "",
          question: "",
        },
      ]);
      setSubmitting(false);
    } else {
      // settoastcontent(response.data.message);
      // setShowtoast(true);
      setSnack({
        ...snack,
        open: true,
        severity: "error",
        text: response.data.message,
      });
    }
    // setemailnews("");
    // console.log(Question);
  };

  return (
    <section className="queryform__main_wrap">
      <div className="queryform__wrap">
        <div className="queryform__left_form">
        {submitting && (
            <div className="overlay__wrap">
               <LazyLoadImage className="overlay__img" src={loader} alt="-" width={100}/>
            </div>
          )}
        <form  onSubmit={handleRegister}>
                    <div className="input-field frominfofields">
                      <LazyLoadImage 
                      width="17" 
                      height="18"
                        src={user}
                        alt="-"
                      />
                      <input
                        type="name"
                        id="inputName"
                        placeholder="Name*"
                        name="name"
                        value={Question[0]["name"]}
                        onChange={(e) => handleQuestions(e)}
                        required
                      />
                    </div>
                    <div className="input-field frominfofields">
                      <LazyLoadImage
                        width="16" 
                        height="16"
                        src={email}
                        alt="-"
                      />
                      <input
                        type="email"
                        id="inputEmail4"
                        placeholder="Email*"
                        name="email"
                        value={Question[0]["email"]}
                        onChange={(e) => handleQuestions(e)}
                        required
                      />
                    </div>
                    <div className="input-field frominfofields">
                      <LazyLoadImage
                          width="18" 
                          height="16"
                        src={formphone}
                        alt="-"
                      />
                      <input
                        type="phone"
                        id="inputphoneNo"
                        placeholder="Phone Number*"
                        name="phoneno"
                        value={Question[0]["phoneno"]}
                        onChange={(e) => handleQuestions(e)}
                        required
                      />
                    </div>
                    <div className="input-field frominfofields">
                      <LazyLoadImage
                          width="16" 
                          height="16"
                        src={company}
                        alt="-"
                      />
                      <input
                        type="text"
                        id="companyName"
                        placeholder="Company Name"
                        name="companyname"
                        value={Question[0]["companyname"]}
                        onChange={(e) => handleQuestions(e)}
                        required
                      />
                    </div>
                    <div className="input-field textarea">
                      <LazyLoadImage
                          width="16" 
                          height="16"
                        src={feedback}
                        alt="-"
                      />
                      <textarea
                        type="text"
                        id="inputQuestion"
                        name="question"
                        placeholder="Question or Feedback "
                        value={Question[0]["question"]}
                        onChange={(e) => handleQuestions(e)}
                        required
                      ></textarea>
                    </div>
                  <div className="rebot justify-content-center">
                    <ReCAPTCHA
                      sitekey="6Ld9BMcjAAAAAE-VKFPaecDHGEqeSKQdQ_GsZ-0W"
                      onChange={onChange}
                      size="normal"
                    />
                  </div>
                    <button
                      type="submit"
                      className="queryform__btn"
                      disabled={!verified}
                    >
                      Request a Call back
                    </button>
                    <button
                      type="submit"
                      className="queryform__btn"
                      disabled={!verified}
                    >
                      Send Message
                    </button>
                </form>
        </div>
        <div className="queryform__right_headers">
                <h2>Any Question?</h2>
                <p>Connect With Us</p>
        </div>
      </div>
    </section>
  );
};

export default AnyQuestion;