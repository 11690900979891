import React from "react";
import { Helmet } from "react-helmet";

export default function HelmetComponent() {
  const TITLE = "Tracking";

  return (
    <Helmet>
      <title>Hajex Bolt | {TITLE} </title>
      <meta name="theme-color" content="#0d6efd" />
      <meta
        name="description"
        content="Track your packages with HAJEX BOLT courier for real-time shipping updates. Easily trace your shipment, check delivery status, and get shipment details online. Track by number and stay informed with our package tracking system."
      />
      <meta
        name="keywords"
        content="HAJEX BOLT tracking, Package tracking,	Shipment tracking Shipping status, Parcel tracking, Track a package, Delivery status,	Track shipment, Track parcel, Package status, HAJEX BOLT courier,	Shipment status, Track and trace,	Track shipment status, HAJEX BOLT, Shipping updates, BOLT shipping,	Package delivery, Shipment details,	HAJEX BOLT delivery, Tracking number, Track by number, Track package,	Shipping information, Track your parcel, Courier tracking, HAJEX tracking number,	Shipping tracking, Track and trace package, Track shipment online, Parcel delivery status, Package tracking system, Track your shipment, Track my package, HAJEX Bolt package tracking,	Shipment tracking online, Track a parcel,	Shipping tracking status"
      />
      <meta name="author" content="Hajex" />
      <meta
        property="og:title"
        content="Package Tracking - Track Your Shipments with Ease at Hajex"
      />
      <meta
        property="og:description"
        content="Hajex offers a comprehensive package tracking solution, allowing you to effortlessly track your packages and shipments. Whether you have tracking codes provided by carriers or prefer to use our unique tracking codes, we provide a user-friendly and reliable tracking experience."
      />
      <meta property="og:image" content="%PUBLIC_URL%/favicon.ico" />
      <meta property="og:url" content="https://hajexbolt.com/tracking" />
      <meta
        property="og:image"
        content="https://hajexbolt.com/static/media/storeconnectimg.8ae073090b0808c0fa50.png"
      />
      <link rel="apple-touch-icon" href="%PUBLIC_URL%/favicon.ico" />
    </Helmet>
  );
}
