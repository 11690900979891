import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import logo1 from "../../Landingpage/images/logo 1.png";
import email from "../../Landingpage/images/email.png";
import phone from "../../Landingpage/images/phone.png";
import { Button } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import group99 from "../../Landingpage/images/Group 99.png";
import axios from "axios";
import { TypeAnimation } from "react-type-animation";
import nationex from "../../Landingpage/images/nationex.png";
import canpar from "../../Landingpage/images/canpar.png";
import canadapost from "../../Landingpage/images/canadapost.png";
import { BsShop, BsTruck } from "react-icons/bs";
import Form from "react-bootstrap/Form";
import DataTable from "react-data-table-component";
import "./Header.css";
import HeaderNavDropdown from "./dropdown";
import styled, { keyframes } from "styled-components";
import { LinkContainer } from "react-router-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
 }

  to {
   transform: rotate(360deg);
  }
  `;
const carrier_logo = [nationex, canpar, canadapost];

const Header = () => {
  const [cancel, setcancel] = useState(true);
  const [eststep, seteststep] = useState("1");
  const [pending, setPending] = useState(true);
  const [EstimateResponse, setEstimateResponse] = useState();
  const [tracking, settracking] = useState(true);
  const [showtoast, setShowtoast] = useState(false);
  const [toastcontent, settoastcontent] = useState();

  let navigate = useNavigate();

  const handleEstimate = (e) => {
    const slist = [...Estimate];
    const { name, value } = e.target;
    slist[0][name] = value;
    setEstimate(slist);
  };
  const [Estimate, setEstimate] = useState([
    {
      pickup: "",
      dropof: "",
      length: "",
      width: "",
      height: "",
      weight: "",
    },
  ]);
  const handleEstimatesubmit = async (event) => {
    event.preventDefault();
    seteststep("2");
    const response = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_URL}landing-page/get-shipping-estimated-rates`,
      data: {
        SourcePostalCode: Estimate[0]["pickup"],
        DestinationPostalCode: Estimate[0]["dropof"],
        Weight: Estimate[0]["weight"],
        Height: Estimate[0]["height"],
        Length: Estimate[0]["length"],
        Width: Estimate[0]["width"],
      },
    });
    // console.log(response.data.data);

    if (response.data.status === true) {
      setEstimateResponse(response.data.data);
      setPending(false);
      settoastcontent(response.data.message);
      setShowtoast(true);
    } else {
      settoastcontent(response.data.message);
      setShowtoast(true);
    }
  };
  const columns = [
    {
      name: "Carrier",
      selector: (row) => (
        <img width="" height="" src={carrier_logo[row.comp_id - 1]} />
      ),
    },
    {
      name: "Estimated Days",
      selector: (row) => row.transit_days + " Days",
    },
    {
      name: "Weight",
      selector: (row) => Estimate[0]["weight"],
    },
    {
      name: "Total",
      selector: (row) => row.total,
    },
    //   {
    //     name: "id",
    //     selector: (row) => row.comp_id,
    //   },
  ];
  const Spinner = styled.div`
    margin: 16px;
    animation: ${rotate360} 1s linear infinite;
    transform: translateZ(0);
    border-top: 2px solid grey;
    border-right: 2px solid grey;
    border-bottom: 2px solid grey;
    border-left: 4px solid black;
    background: transparent;
    width: 80px;
    height: 80px;
    border-radius: 50%;
  `;

  const CustomLoader = () => (
    <div style={{ padding: "24px" }}>
      <Spinner />
      <div> Estimating ...</div>
    </div>
  );

  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleMenuIconClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <>
      {cancel === true && (
        <section className="header__topbar_wrap">
          <div className="haeder_headline_wrapper">
            <div className="header__topbar_left">
              <div className="header__topbar_leftcontent">
                {" "}
                Canada's Best Warehouse and Transportation Management System
                (WMS & TMS) for Businesses, Carriers and 3PL Warehouses.
              </div>
            </div>
            <div className="header__topbar_right ">
              <a
                href="https://ship.hajexbolt.com/Register"
                className="header__topbar_register"
              >
                Register
              </a>
              <div className="header__topbar_close">
                <RxCross2 onClick={() => setcancel(false)} className="cross" />
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="header__menu_info_wrap_desktop">
        <div className="header__toplogo_info_wrap">
          <div className="header__logo">
            <a className="navbar-brand" href="/" aria-label="header logo">
              <img
                width="240"
                height="33"
                src={logo1}
                className="img-fluid"
                alt="-"
              />
            </a>
          </div>
          <div className="header__contact_info">
            <ul className="header__contact_info_ul">
              <li className="header__contact_email">
                <img
                  width="34"
                  height="18"
                  src={email}
                  className="px-2"
                  alt="-"
                />
                <span>
                  <a href="mailto:info@hajexbolt.com">Info@hajexbolt.com</a>
                  <em>Email us</em>
                </span>
              </li>
              <li className="header__contact_phone">
                <img
                  width="34"
                  height="18"
                  src={phone}
                  className="px-2"
                  alt="-"
                />
                <span>
                  <a href="tel:+1 855 999 6659">855 999 6659</a>
                  <em>Talk to an expert</em>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div className="headernav__main_wrap">
          <div className="headernav__main_col">
            <ul className="headernav__list_main">
              <li>
                <LinkContainer to="/">
                  <Nav.Link>Home</Nav.Link>
                </LinkContainer>
              </li>
              <li>
                <LinkContainer to="/about">
                  <Nav.Link>About us</Nav.Link>
                </LinkContainer>
              </li>
              <li>
                <LinkContainer to="/tracking">
                  <Nav.Link>Tracking</Nav.Link>
                </LinkContainer>
              </li>
              {/* <li>
                <Nav.Link href="https://ship.hajexbolt.com/Login?role=carrier">
                  Carrier Portal
                </Nav.Link>
              </li>
              <li>
                <Nav.Link href="https://ship.hajexbolt.com/Login?role=shipper">
                  Shipper Portal
                </Nav.Link>
              </li> */}
              <li>
                <LinkContainer to="/contact">
                  <Nav.Link>Contact us</Nav.Link>
                </LinkContainer>
              </li>
            </ul>
          </div>
          <div className="header__accout_dropdown">
            <HeaderNavDropdown />
          </div>
        </div>
      </section>

      <section className="headernav__main_wrap_mobile">
        <div className="headernav__main_wrap_mobile_row">
          <div className="header__logo">
            <a className="navbar-brand" href="/" aria-label="header logo">
              <img
                width="211"
                height="19"
                src={logo1}
                className="img-fluid"
                alt="-"
              />
            </a>
          </div>
          <div className="header__contact_info">
            <ul className="header__contact_info_ul">
              <li className="header__contact_phone">
                <a href="tel:+1 855 999 6659">
                  {" "}
                  <img
                    width="34"
                    height="18"
                    src={phone}
                    className="px-2"
                    alt="-"
                  />{" "}
                </a>
              </li>
              <li className="header__contact_email">
                <a href="mailto:info@hajexbolt.com">
                  <img
                    width="34"
                    height="18"
                    src={email}
                    className="px-2"
                    alt="-"
                  />
                </a>
              </li>
            </ul>
          </div>
          <div className="headernav__navicon_wrap">
            <button
              className="headernav__navicon_btn"
              onClick={handleMenuIconClick}
              aria-label="Aria Name"
            >
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>

          <div
            className={
              isMenuOpen ? "headernav__main_wrap open" : "headernav__main_wrap"
            }
          >
            <div
              className="header__mobile_overlay"
              onClick={handleMenuIconClick}
            ></div>
            <div className="headernav__main_mobile_wrap">
              <div className="header__logo">
                <a className="navbar-brand" href="/" aria-label="header logo">
                  <img
                    width="211"
                    height="19"
                    src={logo1}
                    className="img-fluid"
                    alt="-"
                  />
                </a>
              </div>
              <button
                className="headernav__btn_cross"
                onClick={handleMenuIconClick}
                aria-label="handle menu Icon"
              >
                <span></span>
                <span></span>
              </button>
              <ul className="headernav__list_main">
                <li>
                  <LinkContainer to="/">
                    <Nav.Link>Home</Nav.Link>
                  </LinkContainer>
                </li>
                <li>
                  <LinkContainer to="/about">
                    <Nav.Link>About us</Nav.Link>
                  </LinkContainer>
                </li>
                <li>
                  <LinkContainer to="/tracking">
                    <Nav.Link>Tracking</Nav.Link>
                  </LinkContainer>
                </li>
                <li>
                  <Nav.Link href="https://ship.hajexbolt.com/Login?role=carrier">
                    Carrier Portal
                  </Nav.Link>
                </li>
                <li>
                  <Nav.Link href="https://ship.hajexbolt.com/Login?role=shipper">
                    Shipper Portal
                  </Nav.Link>
                </li>
                <li>
                  <LinkContainer to="/contact">
                    <Nav.Link>Contact us</Nav.Link>
                  </LinkContainer>
                </li>
              </ul>
              <HeaderNavDropdown />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Header;
