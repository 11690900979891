import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { LazyLoadImage } from "react-lazy-load-image-component";
import lock from "../images/lock.png";
import phone from "../images/phone.png";
import envelope from "../images/envelope.png";
import user from "../images/user.png";
import arrowDown from "../../../Landingpage/images/downArrow.png";

export default function InquireyComponent() {
  const [ticketVal, setTicketVal] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    ticket: "",
    pin: "",
  });

  const [isInquireDrop, setInquireDrop] = useState(false);
  const [isInquireForm, setInquireForm] = useState(false);

  const toggleInquireDrop = () => {
    setInquireDrop(!isInquireDrop);
    setInquireForm(false);
  };

  const toggleInquireForm = (text) => {
    setInquireForm(!isInquireForm);
    setInquireDrop(false);
    setTicketVal(text);
  };

  const toggleInquireFormPop = () => {
    setInquireDrop(false);
    setInquireForm(false);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem("formData", formData);
    console.log(formData);
    setInquireDrop(false);
    setInquireForm(false);
    setFormData(false);
  };

  return (
    <>
      <button
        className="packagedetails__button_inquirebtn"
        onClick={toggleInquireDrop}
      >
        Inquire <LazyLoadImage src={arrowDown} className="px-2" />
      </button>
      {isInquireDrop && (
        <ul className="packagedetails__button_list">
          <li onClick={() => toggleInquireForm("Package Damaged")}>
            Package Damaged
          </li>
          <li onClick={() => toggleInquireForm("Package Missing")}>
            Package Missing
          </li>
          <li onClick={() => toggleInquireForm("Shipment Delayed")}>
            Shipment Delayed
          </li>
          <li onClick={() => toggleInquireForm("Feedback")}>Feedback</li>
          <li onClick={() => toggleInquireForm("Complaint")}>Complaint</li>
          <li onClick={() => toggleInquireForm("Other Reason")}>
            Other Reason
          </li>
        </ul>
      )}
      {isInquireForm && (
        <div className="packagedetails__inquireform_main_wrapper">
          <div
            className="packagedetails__inquireform_overlay"
            onClick={toggleInquireFormPop}
          ></div>
          <div className="packagedetails__inquireform_wrap">
            <div className="packagedetails__inquireform_header">
              <h3>
                Chat Login{" "}
                <span className="packagedetails__inquireform_ticket_purpose">
                  {ticketVal}
                </span>{" "}
              </h3>
              <RxCross2 onClick={toggleInquireFormPop} />
            </div>
            <div className="packagedetails__inquireform_formfields">
              <form
                onSubmit={handleSubmit}
                className="packagedetails__inquireform_form"
              >
                <input
                  type="hidden"
                  name="ticket"
                  value={formData.ticket}
                  onChange={handleChange}
                />
                <div className="packagedetaails__ticketform_field">
                  <LazyLoadImage src={user} alt="-" />
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Name"
                    required
                  />
                </div>
                <div className="packagedetaails__ticketform_field">
                  <LazyLoadImage src={envelope} alt="-" />
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email"
                    required
                  />
                </div>
                <div className="packagedetaails__ticketform_field">
                  <LazyLoadImage src={phone} alt="-" />
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Phone Number"
                    required
                  />
                </div>
                <div className="packagedetaails__ticketform_field">
                  <LazyLoadImage src={lock} alt="-" />
                  <input
                    type="password"
                    name="pin"
                    value={formData.pin}
                    onChange={handleChange}
                    placeholder="Pin Code"
                    required
                  />
                </div>
                <button type="submit">Submit</button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
