import "./JoinCarriers.css";
import image1 from "./images/canpar.png";
import image2 from "./images/vitran.png";
import image3 from "./images/unitedstatespostalservice.png";
// import image4 from "./images/ups.png";
import image5 from "./images/fedex.png";
// import image6 from "./images/purolator.png";
import image7 from "./images/dhl.png";
import image8 from "./images/canadapostes.png";
import image9 from "./images/gls.png";
import image10 from "./images/daysnross.png";
import image11 from "./images/ics.png";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import { Navbar } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";

const images = [
  { src: image1, width: 126, height: 84 },
  { src: image2, width: 136, height: 53 },
  { src: image3, width: 164, height: 92 },
  // { src: image4, width: 66, height: 66 },
  { src: image5, width: 92, height: 92 },
  // { src: image6, width: 160, height: 27 },
  { src: image7, width: 128, height: 128 },
  { src: image8, width: 156, height: 66 },
  { src: image9, width: 88, height: 31 },
  { src: image10, width: 71, height: 98 },
  { src: image11, width: 71, height: 98 },
];

const JoinCarriers = () => {
  return (
    <section className="joincarriers__main_wrap">
      <h2 className="joincarriers__heading">We Ship with</h2>
      <ul className="joincarriers__logos">
        {images.map((image, index) => (
          <li key={index}>
            <LazyLoadImage
              src={image.src}
              alt={`Image-${index}`}
              width={image.width}
              height={image.height}
            />
          </li>
        ))}
      </ul>
      <div className="joincarriers__btn_wrap">
        <LinkContainer to="/" className="joincarriers__anch">
          <Nav.Link>50+ Carriers</Nav.Link>
        </LinkContainer>{" "}
        <span>|</span>{" "}
        <Navbar.Brand
          href="https://ship.hajexbolt.com/Register"
          className="joincarriers__anch"
        >
          Sign up as Carrier
        </Navbar.Brand>
      </div>
    </section>
  );
};

export default JoinCarriers;
