import React from "react";
import "./HeroBanner.css";
import { useEffect } from "react";
import group99 from "../../Landingpage/images/Group 99.png";
import MuiAlert from "@mui/material/Alert";
import Form from "react-bootstrap/Form";
import { TypeAnimation } from "react-type-animation";
import { Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import styled, { keyframes } from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Rating from "@mui/material/Rating";
import testimage from "../../Landingpage/images/test-image.png";
import truck from "./images/fi-rr-truck-side.png";
import pin from "./images/fi-rr-marker.png";
import loader from "./images/est-loader.gif";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useState } from "react";
import {
  BsYoutube,
  BsLinkedin,
  BsFacebook,
  BsTwitter,
  BsPinterest,
  BsShop,
  BsTruck,
} from "react-icons/bs";

const HeroBanner = () => {
  const initial_Estimate = {
    pickup: "",
    fcountry: "",
    fcountrycode: "",
    fstate: "",
    fstatecode: "",
    dropof: "",
    tcountry: "",
    tcountrycode: "",
    tstate: "",
    tstatecode: "",
    length: "",
    width: "",
    height: "",
    weight: "",
  };

  const [tracking, settracking] = useState(false);
  const [Tracking_Id, setTracking_Id] = useState("");
  const images = [testimage, testimage, testimage, testimage];
  const [eststep, seteststep] = useState("1");
  const [pending, setPending] = useState(true);
  const [Estimate, setEstimate] = useState([initial_Estimate]);
  const [unit, setUnit] = useState("Imperial");
  const [EstimateResponse, setEstimateResponse] = useState();
  const [verified, setverified] = useState(true);
  const [emailnews, setemailnews] = useState("");
  const [show, setshow] = useState("1");
  const [showtoast, setShowtoast] = useState(false);
  const [toastcontent, settoastcontent] = useState();
  const [cancel, setcancel] = useState(true);
  const Unit = ["Imperial", "Metric"];
  const [snack, setSnack] = useState({
    open: false,
    severity: "success",
    autoHideDuration: 6000,
    text: "",
    vertical: "top",
    horizontal: "right",
  });

  const rotate360 = keyframes`
      from {
        transform: rotate(0deg);
     }
    
      to {
       transform: rotate(360deg);
      }
      `;

  const Spinner = styled.div`
    margin: 16px;
    animation: ${rotate360} 1s linear infinite;
    transform: translateZ(0);
    border-top: 2px solid grey;
    border-right: 2px solid grey;
    border-bottom: 2px solid grey;
    border-left: 4px solid black;
    background: transparent;
    width: 80px;
    height: 80px;
    border-radius: 50%;
  `;
  let navigate = useNavigate();
  const CustomLoader = () => (
    <div
      style={{
        padding: "24px",
      }}
      className="landing__custom_loader"
    >
      <LazyLoadImage src={loader} alt="-" width={200} />
    </div>
  );
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleTrackingSubmit = async (event) => {
    navigate({
      pathname: "/tracking",
      search: `?tracking_id=${Tracking_Id}`,
    });
  };

  const handleEstimatesubmit = (event) => {
    event.preventDefault();
    setPending(true);
    seteststep("2");

    const payload = {
      SourcePostalCode: Estimate[0]["pickup"],
      DestinationPostalCode: Estimate[0]["dropof"],
      fromCountryCode: Estimate[0]["fcountrycode"],
      toCountryCode: Estimate[0]["tcountrycode"],
      toCountry: Estimate[0]["tcountry"],
      fromCountry: Estimate[0]["fcountry"],
      fromStateCode: Estimate[0]["fstatecode"],
      toStateCode: Estimate[0]["tstatecode"],
      Unit: unit,
      Weight: Estimate[0]["weight"],
      Height: Estimate[0]["height"],
      Length: Estimate[0]["length"],
      Width: Estimate[0]["width"],
    };

    const { REACT_APP_API_URL } = process.env;

    const requests = ["canpar", "gls", "ups", "canadaPost", "fedex", "ics"].map(
      (carrier) => ({
        method: "POST",
        url: `${REACT_APP_API_URL}landing-page/get-shipping-estimated-rates/${carrier}`,
        data: payload,
        headers: {
          Accept: "application/json",
          // Authorization: "Bearer " + localStorage.getItem("Token"),
        },
      })
    );

    const responses = [];
    Promise.all(requests.map((request) => axios(request)))
      .then((results) => {
        for (const result of results) {
          try {
            const response = result.data;
            if (response.result) {
              responses.push(response.result);
            }
          } catch (error) {
            console.error(error);
          }
        }
        const responseData = responses.flat().sort((a, b) => a.total - b.total);
        setEstimateResponse(responseData);
        // console.log(responseData);
        // setEstimate([initial_Estimate]);
        setPending(false);
      })
      .catch((error) => {
        console.error(error);
        setPending(false);
        setSnack({
          ...snack,
          open: true,
          severity: "error",
          text: "Something Went Wrong ",
        });
      });
  };
  const handleQuestions = (e) => {
    const slist = [...Question];
    const { name, value } = e.target;
    slist[0][name] = value;
    setQuestion(slist);
  };

  const handleEstimate = (e) => {
    const slist = [...Estimate];
    const { name, value } = e.target;
    slist[0][name] = value;
    setEstimate(slist);
  };

  const handleReestimate = () => {
    seteststep("1");
    setEstimateResponse("");
    setEstimate([initial_Estimate]);
  };
  const [Question, setQuestion] = useState([
    {
      name: "",
      email: "",
      phoneno: "",
      companyname: "",
      question: "",
    },
  ]);
  const columns = [
    {
      name: "Carrier",
      selector: (row) => (
        <LazyLoadImage
          src={row.img}
          style={{ maxWidth: "50px", filter: "blur(3px)" }}
        />
      ),
    },
    {
      name: "Est Days",
      selector: (row) => row.transit_days + " Days",
      width: "20%",
    },
    {
      name: `Weight (${unit === "Imperial" ? "Lb" : "Kg"})`,
      selector: (row) => Estimate[0].weight,
      width: "20%",
    },
    {
      name: "Total",
      selector: (row) => row.total,
      width: "20%",
    },
    {
      name: "Rating",
      selector: (row) => (
        <Rating
          name="size-small"
          defaultValue={row.rating}
          precision={0.1}
          readOnly
          size="small"
          fill="#0d6efd"
          style={{ color: "#0d6efd" }}
        />
      ),
    },
  ];

  useEffect(() => {
    const lookupPostalCode = async () => {
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${Estimate[0]["pickup"]}&components=country:CA&key=${process.env.REACT_APP_Google_Key}`
        );
        const { results } = response.data;
        if (results.length > 0) {
          const addressComponents = results[0].address_components;
          // console.log(addressComponents);
          const { long_name: state = "" } =
            addressComponents.find((c) =>
              c.types.includes("administrative_area_level_1")
            ) || {};
          const { long_name: county = "" } =
            addressComponents.find((c) => c.types.includes("country")) || {};

          const { short_name: Statecode = "" } =
            addressComponents.find((c) =>
              c.types.includes("administrative_area_level_1")
            ) || {};
          const { short_name: Countrycode = "" } =
            addressComponents.find((c) => c.types.includes("country")) || {};

          // const { long_name: city1 = "" } =
          //   addressComponents.find((c) => c.types.includes("sublocality")) || {};

          // const { long_name: city2 = "" } =
          //   addressComponents.find((c) => c.types.includes("locality")) || {};

          const slist = [...Estimate];
          slist[0]["fcountry"] = county;
          slist[0]["fcountrycode"] = Countrycode;
          slist[0]["fstate"] = state;
          slist[0]["fstatecode"] = Statecode;
          setEstimate(slist);
        }
      } catch (error) {
        console.error("Error:", error);
        // Handle error, e.g., show an error message to the user
      }
    };

    if (Estimate[0]["pickup"].length === 6) {
      // console.log(Estimate[0]["pickup"]);
      lookupPostalCode();
    }
  }, [Estimate[0]["pickup"]]);

  useEffect(() => {
    const lookupPostalCode = async () => {
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${Estimate[0]["dropof"]}&components=country:CA&key=${process.env.REACT_APP_Google_Key}`
        );
        const { results } = response.data;
        if (results.length > 0) {
          const addressComponents = results[0].address_components;
          // console.log(addressComponents);
          const { long_name: state = "" } =
            addressComponents.find((c) =>
              c.types.includes("administrative_area_level_1")
            ) || {};
          const { long_name: county = "" } =
            addressComponents.find((c) => c.types.includes("country")) || {};

          const { short_name: Statecode = "" } =
            addressComponents.find((c) =>
              c.types.includes("administrative_area_level_1")
            ) || {};
          const { short_name: Countrycode = "" } =
            addressComponents.find((c) => c.types.includes("country")) || {};

          // const { long_name: city1 = "" } =
          //   addressComponents.find((c) => c.types.includes("sublocality")) || {};

          // const { long_name: city2 = "" } =
          //   addressComponents.find((c) => c.types.includes("locality")) || {};

          const slist = [...Estimate];
          slist[0]["tcountry"] = county;
          slist[0]["tcountrycode"] = Countrycode;
          slist[0]["tstate"] = state;
          slist[0]["tstatecode"] = Statecode;
          setEstimate(slist);
        }
      } catch (error) {
        console.error("Error:", error);
        // Handle error, e.g., show an error message to the user
      }
    };

    if (Estimate[0]["dropof"].length === 6) {
      // console.log(Estimate[0]["pickup"]);
      lookupPostalCode();
    }
  }, [Estimate[0]["dropof"]]);

  useEffect(() => {
    function removeSpaces(str) {
      return str.replace(/\s/g, "");
    }
    const slist = [...Estimate];
    slist[0]["pickup"] = removeSpaces(Estimate[0]["pickup"]);
    setEstimate(slist);
  }, [Estimate[0]["pickup"]]);

  useEffect(() => {
    function removeSpaces(str) {
      return str.replace(/\s/g, "");
    }
    const slist = [...Estimate];
    slist[0]["dropof"] = removeSpaces(Estimate[0]["dropof"]);
    setEstimate(slist);
  }, [Estimate[0]["dropof"]]);

  return (
    <section className="hero__banner_wrap">
      <div className="hero__banner_heading_wrap">
        <h2 className="hero__banner_heading">
          Connecting Businesses with
          <span className="color-side">Reliable Shippers </span>
        </h2>
      </div>
      <div className="hero__tracking_wrap">
        {tracking === true && (
          <div className="hero__tracking_form">
            <Button
              className="hero__form_tabs_btn"
              variant={tracking === true ? "light" : "primary"}
              onClick={() => settracking(false)}
            >
              <LazyLoadImage
                width="23"
                height="15"
                src={truck}
                className="px-2"
              />
              SHIPPING ESTIMATE
            </Button>
            <Button
              className="hero__form_tabs_btn"
              variant={tracking === true ? "primary" : "light"}
              onClick={() => settracking(true)}
            >
              <LazyLoadImage
                width="23"
                height="15"
                src={pin}
                className="px-2"
                alt="-"
              />
              TRACKING
            </Button>
            <Form onSubmit={handleTrackingSubmit}>
              <input
                className="ero__tracking_form_trackfld"
                style={{
                  BorderBottomRightRadius: "0",
                  BorderTopRightRadius: "0",
                }}
                type="text"
                placeholder="Enter the tracking ID"
                value={Tracking_Id}
                onChange={(event) => setTracking_Id(event.target.value)}
                required
              />
              <button
                className="hero__form_track_submit_btn"
                id="Tracking_Id"
                type="submit"
                style={{
                  BorderBottomLeftRadius: "0",
                  BorderTopLeftRadius: "0",
                }}
              >
                {" "}
                TRACK
              </button>
            </Form>
          </div>
        )}
        {tracking === false && (
          <>
            <div className="hero__tracking_form">
              <Button
                className="hero__form_tabs_btn"
                variant={tracking === true ? "light" : "primary"}
                onClick={() => settracking(false)}
              >
                <LazyLoadImage
                  width="23"
                  height="15"
                  src={truck}
                  className="px-2"
                  alt="-"
                />
                SHIPPING ESTIMATE
              </Button>
              <Button
                className="hero__form_tabs_btn"
                variant={tracking === true ? "primary" : "light"}
                onClick={() => settracking(true)}
              >
                <LazyLoadImage
                  width="23"
                  height="15"
                  src={pin}
                  className="px-2"
                  alt="-"
                />
                TRACKING
              </Button>

              <Form onSubmit={handleEstimatesubmit}>
                <div className="hero__estimation_form_wrap">
                  <span className="hero__estimation_form_area_est">
                    <label>From</label>
                    <input
                      type="text"
                      placeholder="Pickup Postal code"
                      name="pickup"
                      value={Estimate[0]["pickup"]}
                      onChange={(e) => handleEstimate(e)}
                      required
                    />
                  </span>
                  <span className="hero__estimation_form_area_est">
                    <label>To</label>
                    <input
                      type="text"
                      placeholder="Destination Postal Code"
                      name="dropof"
                      value={Estimate[0]["dropof"]}
                      onChange={(e) => handleEstimate(e)}
                      required
                    />
                  </span>
                  <span className="hero__estimation_form_dimention_est">
                    <label>
                      Size (L*W*H)({unit === "Imperial" ? "In" : "Cm"})
                    </label>
                    <input
                      type="number"
                      placeholder={
                        unit === "Imperial" ? "Length /in" : "Length /cm"
                      }
                      name="length"
                      value={Estimate[0]["length"]}
                      onChange={(e) => handleEstimate(e)}
                      min="0"
                      required
                    />
                    <input
                      type="number"
                      placeholder={
                        unit === "Imperial" ? "Width /in" : "Width /cm"
                      }
                      name="width"
                      value={Estimate[0]["width"]}
                      onChange={(e) => handleEstimate(e)}
                      min="0"
                      required
                    />
                    <input
                      type="number"
                      placeholder={
                        unit === "Imperial" ? "Height /in" : "Height /cm"
                      }
                      name="height"
                      value={Estimate[0]["height"]}
                      onChange={(e) => handleEstimate(e)}
                      min="0"
                      required
                    />
                  </span>
                  <span className="hero__estimation_form_weight_est">
                    <label>Weight ({unit === "Imperial" ? "Lb" : "Kg"})</label>
                    <input
                      type="number"
                      placeholder={
                        unit === "Imperial" ? "Weight /Lbs" : "Weight /kg"
                      }
                      name="weight"
                      value={Estimate[0]["weight"]}
                      onChange={(e) => handleEstimate(e)}
                      min="0"
                      required
                    />
                  </span>
                </div>

                <div>
                  <h2 className="hero__estimation_unit_heading">
                    Unit of Measurement
                  </h2>
                  <Form.Check
                    inline
                    label="Imperial"
                    value="Imperial"
                    name="Imperial"
                    type="checkbox"
                    checked={unit === "Imperial"}
                    onChange={(event) => setUnit(event.target.value)}
                    id="Imperial"
                    className="imp_met"
                  />

                  <Form.Check
                    inline
                    label="Metric"
                    value="Metric"
                    name="Metric"
                    type="checkbox"
                    checked={unit === "Metric"}
                    onChange={(event) => setUnit(event.target.value)}
                    id="Metric"
                    className="imp_met"
                  />
                </div>
                {eststep === "1" && (
                  <div className="hero__estimation_submit_wrap">
                    <button
                      type="submit"
                      className="hero__estimation_submit_btn"
                      style={{ float: "right", borderRadius: "0px" }}
                    >
                      Estimate
                    </button>
                  </div>
                )}
              </Form>
              {eststep === "2" && (
                <div className="hero__tracking_response_wrap">
                  {pending === false && (
                    <h3 className="tracking__response_heading">QUOTE</h3>
                  )}

                  <DataTable
                    columns={columns}
                    data={EstimateResponse}
                    fixedHeader
                    fixedHeaderScrollHeight="600px"
                    highlightOnHover
                    progressPending={pending}
                    progressComponent={<CustomLoader />}
                  />

                  {pending === false && (
                    <div className="hero__tracking_infobtn_wrap">
                      <a
                        href="https://ship.hajexbolt.com/Register"
                        class="hero__form_signup_btn"
                      >
                        Sign Up
                      </a>
                      <button
                        onClick={handleReestimate}
                        className="hero__form_refresh_btn"
                      >
                        Refresh
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </section>
  );
};
export default HeroBanner;
