import React, { useState } from "react";
import { ReactComponent as Tick } from "../../../assets/icons/tick.svg";
import "./SortReviews.css";

const SortReviews = () => {
  const [sortBy, setSortBy] = useState("date");
  const [sortDate, setSortDate] = useState("latest");
  const [sortRating, setSortRating] = useState("ascend");

  return (
    <div className="sort__reviews_modal">
      <div className="sortrev__modal_heading">Sort By</div>
      <div className="sortrev__modal_tabs">
        <div
          className={`sortrev__modal_tab ${
            sortBy === "date" && "sortrev__modal_tabactive"
          }`}
          onClick={() => setSortBy("date")}
        >
          Date
        </div>
        <div
          className={`sortrev__modal_tab ${
            sortBy === "rating" && "sortrev__modal_tabactive"
          }`}
          onClick={() => setSortBy("rating")}
        >
          Rating
        </div>
      </div>
      {sortBy === "date" && (
        <div className="sortrev__modal_content">
          <div
            className={`sortrev__modal_contentitem ${
              sortDate === "latest" && "sortrev__modal_contentitemact"
            }`}
            onClick={() => setSortDate("latest")}
          >
            <div className="sortrev__modal_contentitemtxt">
              Sort By Latest Date
            </div>
            <div className="sortrev__modal_contentitemicon">
              <Tick />
            </div>
          </div>
          <div
            className={`sortrev__modal_contentitem ${
              sortDate === "oldest" && "sortrev__modal_contentitemact"
            }`}
            onClick={() => setSortDate("oldest")}
          >
            <div className="sortrev__modal_contentitemtxt">
              Sort By Oldest Date
            </div>
            <div className="sortrev__modal_contentitemicon">
              <Tick />
            </div>
          </div>
        </div>
      )}
      {sortBy === "rating" && (
        <div className="sortrev__modal_content">
          <div
            className={`sortrev__modal_contentitem ${
              sortRating === "ascend" && "sortrev__modal_contentitemact"
            }`}
            onClick={() => setSortRating("ascend")}
          >
            <div className="sortrev__modal_contentitemtxt">Ascending Order</div>
            <div className="sortrev__modal_contentitemicon">
              <Tick />
            </div>
          </div>
          <div
            className={`sortrev__modal_contentitem ${
              sortRating === "descend" && "sortrev__modal_contentitemact"
            }`}
            onClick={() => setSortRating("descend")}
          >
            <div className="sortrev__modal_contentitemtxt">
              Descending Order
            </div>
            <div className="sortrev__modal_contentitemicon">
              <Tick />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SortReviews;
