import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import BlueStar from "../../../assets/icons/blue-star.svg";
import GreyStar from "../../../assets/icons/grey-star.svg";
import ImageIcon from "../../../assets/images/image-icon.png";
import Form from "react-bootstrap/Form";
import Close from "../../../assets/icons/close.svg";
import "./AddReview.css";
import { Link } from "react-router-dom";

function AddReview({ open, setOpen }) {
  const [selectedImages, setSelectedImages] = useState([]);
  const [previewImageUrls, setPreviewImageUrls] = useState([]);
  const handleClose = () => setOpen(false);
  const handleSubmit = () => setOpen(false);
  const handleImageChange = (e) => {
    const files = Array.from(e.target.files).slice(0, 3);

    const validFiles = files.filter(
      (file) => file.type === "image/jpeg" || file.type === "image/png"
    );

    const numInvalidFiles = files.length - validFiles.length;

    if (validFiles.length > 0) {
      setSelectedImages(validFiles);
      const urls = validFiles.map((file) => URL.createObjectURL(file));
      setPreviewImageUrls(urls);
    } else {
      setSelectedImages([]);
      setPreviewImageUrls([]);
      alert("Please select up to 3 JPG or PNG image files.");
    }

    if (numInvalidFiles > 0) {
      alert(
        `Only ${validFiles.length} of the ${files.length} selected files are valid JPG or PNG images.`
      );
    }
  };

  return (
    <Modal show={open} onHide={handleClose}>
      <div className="add__review_modal">
        <div className="add__review_modalclose" onClick={handleClose}>
          <img src={Close} />
        </div>
        <h3 className="add__review_modalheading">Overall rating</h3>
        <div className="add__review_modalstars">
          {[1, 1, 1, 0, 0].map((item) => {
            return (
              <img
                src={item ? BlueStar : GreyStar}
                className="add__review_modalstar"
              />
            );
          })}
        </div>
        <div className="add__revmodal_formitem">
          <div className="add__revmodal_formlabel">Review title</div>
          <input className="add__revmodal_forminput" />
        </div>
        <div className="add__revmodal_formitem">
          <div className="add__revmodal_formlabel">
            Would you recommend this Shipping service to a friend?
          </div>
          <div className="add__revmodal_formradios mb-3" key="inline-radio">
            <div className="add__revmodal_formradio">
              <Form.Check
                inline
                name="recommended"
                type="radio"
                id={`inline-radio-1`}
              />
              <label
                className="add__revmodal_formradiolbl"
                for="inline-radio-1"
              >
                Yes
              </label>
            </div>
            <div className="add__revmodal_formradio">
              <Form.Check
                inline
                name="recommended"
                type="radio"
                id={`inline-radio-2`}
              />
              <label
                className="add__revmodal_formradiolbl"
                for="inline-radio-2"
              >
                No
              </label>
            </div>
          </div>
        </div>
        <div className="add__revmodal_formitem">
          <div className="add__revmodal_formlabel">Review</div>
          <textarea className="add__revmodal_formtextarea"></textarea>
        </div>
        <div className="add__revmodal_formitem">
          <div className="add__revmodal_formlabel">Nickname</div>
          <input className="add__revmodal_forminput" />
        </div>
        <div className="add__revmodal_formitem">
          <div className="add__revmodal_formlabel">Email Address</div>
          <input className="add__revmodal_forminput" />
        </div>
        <div className="add__revmodal_formitemimgs">
          <label className="add__revmodal_imageupload" for="review-images">
            <input
              type="file"
              id="review-images"
              accept=".jpg, .png"
              multiple
              onChange={handleImageChange}
              className="add__revmodal_imageuploadfield"
            />
            <img src={ImageIcon} className="add__revmodal_imageicon" />
            <div className="add__revmodal_imagetxt">upload image</div>
          </label>

          {previewImageUrls.map((url, index) => (
            <div className="add__revmodal_imagepreview">
              <div className="add__revmodal_imagepreviewclose">
                <img
                  src={Close}
                  onClick={() =>
                    setPreviewImageUrls(
                      previewImageUrls.filter((item, idx) => idx !== index)
                    )
                  }
                />
              </div>
              <img
                key={index}
                src={url}
                alt={`Preview ${index + 1}`}
                style={{ maxWidth: "100%" }}
              />{" "}
            </div>
          ))}
        </div>
        <div className="add__revmodal_formcheck">
          <Form.Check
            inline
            name="recommended"
            type="checkbox"
            id={`inline-checkbox-1`}
          />
          <label
            className="add__revmodal_formcheckboxlbl"
            for="inline-checkbox-1"
          >
            I accept the <Link to="/terms"> terms and conditions</Link>
          </label>
        </div>
        <button className="add__revmodal_formsubmitbtn" onClick={handleSubmit}>
          Submit Review
        </button>
      </div>
    </Modal>
  );
}

export default AddReview;
