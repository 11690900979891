import React, { useState } from "react";

export default function ShipmentHistory({ trackingstatus }) {
  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartPosition(e.clientX);
    setScrollLeft(e.currentTarget.scrollLeft);
    e.preventDefault();
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseLeave = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const deltaX = e.clientX - startPosition;
    e.currentTarget.scrollLeft = scrollLeft - deltaX;
  };

  return (
    <div
      className={`trackingstatus__main_wrap ${isDragging ? "dragging" : ""}`}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
    >
      {" "}
      <div className="trackingstatus__main_col">
        {trackingstatus?.map((item, index) => (
          <div
            key={index}
            className={
              "trackingstatus__list " +
              (item?.ShipmentStatus.toLowerCase().replace(/ /g, "") ?? "-") +
              (index % 6 === 0 ? " no-dashes" : "")
            }
          >
            <div
              className="trackingstatus__list_item shipmentstatus"
              data-field="ShipmentStatus"
            >
              {" "}
              <span>{item?.ShipmentStatus ?? "-"}</span>
            </div>
            <div
              className="trackingstatus__list_item statusdate"
              data-field="StatusDate"
            >
              {" "}
              <span>{item?.StatusDate ?? "-"}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
