import React, { useEffect } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import "./PrivacyPolicy.css";
const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="privacy__policy_screen">
      <Header />
      <div className="privacy__policy_content">
        <h1 className="privacy__policy_heading">Hajexbolt Privacy policy</h1>
        <p className="privacy__policy_desc">
          At hajexbolt, accessible from&nbsp;
          <a href="https://hajexbolt.com/" className="privacy__policy_link">
            https://hajexbolt.com
          </a>
          , one of our main priorities is the privacy of our visitors. This
          Privacy Policy document contains types of information that is
          collected and recorded by hajexbolt.com and how we use it.
        </p>
        <p className="privacy__policy_desc">
          If you have additional questions or require more information about our
          Privacy Policy, do not hesitate to contact us at &nbsp;
          <a href="https://hajexbolt.com/" className="privacy__policy_link">
            https://hajexbolt.com
          </a>
          .
        </p>
        <p className="privacy__policy_desc">
          This Privacy Policy applies only to our online activities and is valid
          for visitors to our website with regard to the information that they
          shared and/or collect on hajexbolt.com. This policy is not applicable
          to any information collected offline or via channels other than this
          website.
        </p>
        <h1 className="privacy__policy_heading">Consent</h1>
        <p className="privacy__policy_desc">
          By using our website, you hereby consent to our Privacy Policy and
          agree to its terms and conditions.
        </p>
        <h1 className="privacy__policy_heading">Information we collect</h1>
        <p className="privacy__policy_desc">
          The personal information that you are asked to provide, and the
          reasons why you are asked to provide it, will be made clear to you at
          the point we ask you to provide your personal information.
        </p>
        <p className="privacy__policy_desc">
          If you contact us directly, we may receive additional information
          about you such as your name, email address, phone number, the contents
          of the message and/or attachments you may send us, and any other
          information you may choose to provide.
        </p>
        <p className="privacy__policy_desc">
          When you register for an Account, we may ask for your contact
          information, including items such as name, company name, address,
          email address, and telephone number.
        </p>
        <h1 className="privacy__policy_heading">How we use your information</h1>
        <p className="privacy__policy_desc">
          We use the information we collect in various ways, including:
        </p>
        <ul className="privacy__policy_desclist">
          <li>Provide, operate, and maintain our website</li>
          <li>Improve, personalize, and expand our website</li>
          <li>Understand and analyze how you use our website</li>
          <li>Develop new products, services, features, and functionality</li>
          <li>
            Communicate with you, either directly or through one of our
            partners, including for customer service, to provide you with
            updates and other information relating to the website, and for
            marketing and promotional purposes
          </li>
          <li>Send you emails</li>
          <li>Find and prevent fraud</li>
        </ul>
        <h1 className="privacy__policy_heading">Log Files</h1>
        <p className="privacy__policy_desc">
          hajexbolt.com follows a standard procedure of using log files. These
          files log visitors when they visit websites. All hosting companies do
          this and are a part of hosting services' analytics. The information
          collected by log files includes internet protocol (IP) addresses,
          browser type, Internet Service Provider (ISP), date and time stamp,
          referring/exit pages, and possibly the number of clicks. These are not
          linked to any information that is personally identifiable. The purpose
          of the information is for analyzing trends, administering the site,
          tracking users' movement on the website, and gathering demographic
          information.
        </p>
        <h1 className="privacy__policy_heading">Log Files</h1>
        <p className="privacy__policy_desc">
          You may consult this list to find the Privacy Policy for each of the
          advertising partners of hajexbolt.com.
        </p>
        <p className="privacy__policy_desc">
          Third-party ad servers or ad networks uses technologies like cookies,
          JavaScript, or Web Beacons that are used in their respective
          advertisements and links that appear on hajexbolt.com, which are sent
          directly to users' browser. They automatically receive your IP address
          when this occurs. These technologies are used to measure the
          effectiveness of their advertising campaigns and/or to personalize the
          advertising content that you see on websites that you visit.
        </p>
        <p className="privacy__policy_descdark">
          With third-party service providers who provide website, application
          development, hosting, maintenance, and other services for us. These
          third parties may have access to or process Personal Information as
          part of providing those services for us. We limit the information
          provided to these service providers to that which is reasonably
          necessary for them to perform their functions, and our contracts with
          them require them to maintain the confidentiality of such information
        </p>
        <p className="privacy__policy_desc">
          Note that hajexbolt.com has no access to or control over these cookies
          that are used by third-party advertisers.
        </p>
        <h1 className="privacy__policy_heading">
          Third-Party Privacy Policies
        </h1>
        <p className="privacy__policy_desc">
          hajexbolt.com's Privacy Policy does not apply to other advertisers or
          websites. Thus, we are advising you to consult the respective Privacy
          Policies of these third-party ad servers for more detailed
          information. It may include their practices and instructions about how
          to opt out of certain options. You can choose to disable cookies
          through your individual browser options. To know more detailed
          information about cookie management with specific web browsers, it can
          be found on the browsers' respective websites.
        </p>
        <h1 className="privacy__policy_heading">
          <span className="privacy__policy_highlight">CCPA Privacy Rights</span>{" "}
          (Do Not Sell My Personal Information)
        </h1>
        <p className="privacy__policy_desc">
          Under the CCPA, among other rights, California consumers have the
          right to:
        </p>
        <p className="privacy__policy_desc">
          Request that a business that collects a consumer's personal data
          disclose the categories and specific pieces of personal data that a
          business has collected about consumers.
        </p>
        <p className="privacy__policy_desc">
          Request that a business deletes any personal data about the consumer
          that a business has collected.
        </p>
        <p className="privacy__policy_desc">
          Request that a business that sells a consumer's personal data, not
          sell the consumer's personal data
        </p>
        <p className="privacy__policy_desc">
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us at{" "}
          <a href="https://hajexbolt.com/" className="privacy__policy_link">
            https://hajexbolt.com
          </a>
          .
        </p>
        <h1 className="privacy__policy_heading">GDPR Data Protection Rights</h1>
        <p className="privacy__policy_desc">
          We would like to make sure you are fully aware of all of your data
          protection rights. Every user is entitled to the following:
        </p>
        <p className="privacy__policy_desc">
          The right to access – You have the right to request copies of your
          personal data. We may charge you a small fee for this service.
        </p>
        <p className="privacy__policy_desc">
          The right to rectification – You have the right to request that we
          correct any information you believe is inaccurate. You also have the
          right to request that we complete the information you believe is
          incomplete.
        </p>
        <p className="privacy__policy_desc">
          The right to erasure – You have the right to request that we erase
          your personal data, under certain conditions.
        </p>
        <p className="privacy__policy_desc">
          The right to restrict processing – You have the right to request that
          we restrict the processing of your personal data, under certain
          conditions.
        </p>
        <p className="privacy__policy_desc">
          The right to object to processing – You have the right to object to
          our processing of your personal data, under certain conditions.
        </p>
        <p className="privacy__policy_desc">
          The right to data portability – You have the right to request that we
          transfer the data that we have collected to another organization, or
          directly to you, under certain conditions.
        </p>
        <p className="privacy__policy_desc">
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us
        </p>
        <h1 className="privacy__policy_heading">Children's Information</h1>
        <p className="privacy__policy_desc">
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
        </p>
        <p className="privacy__policy_desc">
          hajexbolt.com does not knowingly collect any Personal Identifiable
          Information from children under the age of 13. If you think that your
          child provided this kind of information on our website, we strongly
          encourage you to contact us immediately and we will do our best
          efforts to promptly remove such information from our records.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
