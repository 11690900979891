import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import Landingpage from "./Landingpage/Landing";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import Tracking from "./pages/Tracking/Tracking";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Terms from "./pages/PrivacyPolicy/Terms";
import CarrierReviews from "./pages/CarrierReviews/CarrierReviews";
import BoltPartner from "./pages/BoltPartner/BoltPartner";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landingpage />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/tracking" element={<Tracking />} />
          <Route path="/carrier-reviews" element={<CarrierReviews />} />
          <Route path="/policy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<Terms />} />
          {/* <Route path="/boltpartner" element={<BoltPartner />} /> */}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
