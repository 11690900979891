import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import Artboard from "../../assets/images/artboard.png";
import Canpar from "../../assets/images/canpar-office.png";
import PlaceholderImage from "../../assets/images/image-placeholder1.png";
import PlaceholderLogo from "../../assets/images/logo-placeholder1.jpg";
import NoContent from "../../assets/images/no-content.png";
import BlueStar from "../../assets/icons/blue-star.svg";
import GreyStar from "../../assets/icons/grey-star.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ReactComponent as Sort } from "../../assets/icons/sort.svg";
import Close from "../../assets/icons/close.svg";
import "./CarrierReviews.css";
import AddReview from "./AddReview/AddReview";
import SortReviews from "./SortReviews/SortReviews";
import ReviewItem from "./ReviewItem/ReviewItem";
import ReviewsPagination from "./ReviewsPagination/ReviewsPagination";
import Zoning from "./Zoning/Zoning";
import axios from "axios";
import { useParams } from "react-router-dom";
import Countries from "../../data/countries";
import Countries2 from "../../data/countries2";

const reviewsRatio = ["75%", "10%", "8%", "5%", "2%"];

const CarrierReviews = () => {
  const [aboutTab, setAboutTab] = useState("about");
  const [reviewTab, setReviewTab] = useState("reviews");
  const [reviewFilter, setReviewFilter] = useState([]);
  const [addReviewModal, setAddReviewModal] = useState(false);
  const [sortModal, setSortModal] = useState(false);
  const [company, setCompany] = useState({});
  const [loading, setLoading] = useState(true);
  const [countries, setCountries] = useState([]);
  const [ratingDistribution, setRatingDistribution] = useState([]);
  const params = new URLSearchParams(window.location.search);
  const fetchCompany = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}rating-setting/get-by-id/${params.get(
          "id"
        )}`
      );
      setCompany(response.data.result);
      setCountries(response.data.result?.ratingResponse?.rating_country);
      calculateRatingDistribution(
        response?.data?.result?.ratingResponse?.rating
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const handleReviewFilter = (value) => {
    setReviewFilter((prevState) => {
      const index = prevState.indexOf(value);
      if (index !== -1) {
        return [...prevState.slice(0, index), ...prevState.slice(index + 1)];
      } else {
        return [...prevState, value];
      }
    });
  };
  function calculateRatingDistribution(ratings) {
    // console.log(ratings);
    const ratingCounts = [0, 0, 0, 0, 0];
    for (let i = 0; i < ratings.length; i++) {
      const rating = ratings[i];
      ratingCounts[rating.rating - 1]++;
    }
    const totalRatings = ratingCounts.reduce((total, count) => total + count);
    const ratingDistribution = ratingCounts.map((count) =>
      Math.round((count / totalRatings) * 100)
    );
    // console.log(ratingDistribution);
    setRatingDistribution(ratingDistribution.reverse());
  }
  useEffect(() => {
    // console.log(params);
    fetchCompany();
  }, []);
  return (
    <div className="carrier__reviews_screen">
      <Header />
      <AddReview open={addReviewModal} setOpen={setAddReviewModal} />
      {!loading && company?.ratingResponse?.name && (
        <div className="about__screen_banner">
          <img src={Artboard} className="abouts__banner_artboard" />
          <div className="abtscreen__banner_title">
            <span>Home</span>
            <span className="abtscreen__banner_titlearrow">{`>`}</span>
            <span className="abtscreen__banner_titleactive">Carriers</span>
            <span className="abtscreen__banner_titlearrow">{`>`}</span>
            <span className="abtscreen__banner_titleactive">
              {company?.ratingResponse?.name}
            </span>
          </div>
          <div className="abtscreen__banner_heading">
            {company?.ratingResponse?.name} Profile and Reviews
          </div>
        </div>
      )}
      {!loading && company?.ratingResponse?.rating_setting?.length > 0 && (
        <div className="carrier__reviewscreen_content">
          <div className="carrierrs___content_about">
            <div className="carrierrs___content_aboutleft">
              <div className="carrierrs___content_aboutleftbtns">
                <div
                  className={`carrierrs___content_aboutleftbtn ${
                    aboutTab === "history" && "carrscontent__albactive"
                  }`}
                  onClick={() => setAboutTab("history")}
                >
                  History
                </div>
                <div
                  className={`carrierrs___content_aboutleftbtn ${
                    aboutTab === "about" && "carrscontent__albactive"
                  }`}
                  onClick={() => setAboutTab("about")}
                >
                  About
                </div>
              </div>
              {aboutTab === "about" && (
                <div className="carrscontent__left_content">
                  <h2 className="carrscontent__lcontent_heading">
                    About {company?.ratingResponse?.name}
                  </h2>
                  <p className="carrscontent__lcontent_desc">
                    {company?.ratingResponse?.rating_setting[1]?.description}
                  </p>
                </div>
              )}
              {aboutTab !== "about" && (
                <div className="carrscontent__left_content">
                  <h2 className="carrscontent__lcontent_heading">
                    {company?.ratingResponse?.name} History
                  </h2>
                  <p className="carrscontent__lcontent_desc">
                    {company?.ratingResponse?.rating_setting[0]?.description}
                  </p>
                </div>
              )}
            </div>
            <div className="carrierrs___content_aboutright">
              <img
                src={company?.ratingResponse?.logo_url ?? PlaceholderLogo}
                onError={(e) => {
                  e.target.src = PlaceholderLogo;
                }}
                className="carrsabtright__logo"
              />
              {aboutTab === "about" && (
                <img
                  src={
                    company?.ratingResponse?.rating_setting[1]?.image ??
                    PlaceholderImage
                  }
                  onError={(e) => {
                    e.target.src = PlaceholderImage;
                  }}
                  className="carrsabtright__mainimg"
                  alt=""
                />
              )}
              {aboutTab !== "about" && (
                <img
                  src={
                    company?.ratingResponse?.rating_setting[0]?.image ??
                    PlaceholderImage
                  }
                  onError={(e) => {
                    e.target.src = PlaceholderImage;
                  }}
                  className="carrsabtright__mainimg"
                  alt=""
                />
              )}
            </div>
          </div>
          <div className="carrierrs___content_zoning">
            <h2 className="carrierrs___content_zoningheading">Zoning</h2>
            <Zoning countries={countries} />
          </div>
          <div className="carrierrs___content_reviews">
            <h1 className="carrscontent__reviews_heading">Reviews</h1>
            <div className="carrscontent__reviews_options">
              <div className="carrscontent__reviews_optionsleft">
                <div
                  className={`carrscontent__reviews_olrvs ${
                    reviewTab === "reviews" && "carrscontent__reviews_optactive"
                  }`}
                  onClick={() => setReviewTab("reviews")}
                >
                  <div
                    className={`carrscontent__reviews_olrvslft ${
                      reviewTab === "reviews" &&
                      "carrscontent__reviews_opttxtactive"
                    }`}
                  >
                    Reviews
                  </div>
                  <div className="carrscontent__reviews_olrvsrit">23,980</div>
                </div>
                <div
                  className={`carrscontent__reviews_olrvs ${
                    reviewTab === "questions" &&
                    "carrscontent__reviews_optactive"
                  }`}
                  onClick={() => setReviewTab("questions")}
                >
                  <div
                    className={`carrscontent__reviews_olrvslft ${
                      reviewTab === "questions" &&
                      "carrscontent__reviews_opttxtactive"
                    }`}
                  >
                    Questions
                  </div>
                  <div className="carrscontent__reviews_olrvsrit">23,980</div>
                </div>
              </div>
              <div className="carrscontent__reviews_optionsright">
                <div
                  className={`carrscontent__reviews_filter `}
                  onClick={() => handleReviewFilter("shipper")}
                >
                  <input
                    type="checkbox"
                    name="reviewfilter"
                    checked={reviewFilter.includes("shipper")}
                    className="crcreview__filter_checkbox"
                  />
                  <div
                    className={`carrscontent__reviews_filterlft ${
                      reviewFilter.includes("shipper") &&
                      "carrscontent__reviews_opttxtactive"
                    }`}
                  >
                    Shipper
                  </div>
                  <div className="carrscontent__reviews_filterrit">23</div>
                </div>
                <div
                  className={`carrscontent__reviews_filter `}
                  onClick={() => handleReviewFilter("recipient")}
                >
                  <input
                    type="checkbox"
                    name="reviewfilter"
                    checked={reviewFilter.includes("recipient")}
                    className="crcreview__filter_checkbox"
                  />
                  <div
                    className={`carrscontent__reviews_filterlft ${
                      reviewFilter.includes("recipient") &&
                      "carrscontent__reviews_opttxtactive"
                    }`}
                  >
                    Recipient
                  </div>
                  <div className="carrscontent__reviews_filterrit">23,980</div>
                </div>
                <div
                  className={`carrscontent__reviews_filter `}
                  onClick={() => handleReviewFilter("others")}
                >
                  <input
                    type="checkbox"
                    name="reviewfilter"
                    checked={reviewFilter.includes("others")}
                    className="crcreview__filter_checkbox"
                  />
                  <div
                    className={`carrscontent__reviews_filterlft ${
                      reviewFilter.includes("others") &&
                      "carrscontent__reviews_opttxtactive"
                    }`}
                  >
                    Others
                  </div>
                  <div className="carrscontent__reviews_filterrit">23,980</div>
                </div>
              </div>
            </div>
            <div className="carrscontent__reviews_boxs">
              <div className="carrscontent__reviews_boxleft">
                <div className="carrscontent__reviews_boxleftheading">
                  {company?.ratingAverage?.slice(0, 3)}
                </div>
                <div className="carrscontent__reviews_boxleftdesc">
                  Based on {company?.ratingResponse?.rating?.length} review
                  {company?.ratingResponse?.rating?.length > 1 && "s"}
                </div>
                <div className="carrscontent__reviews_boxleftstars">
                  {[1, 1, 1, 0, 0].map((item, index) => {
                    return (
                      <img
                        src={
                          index + 1 <= company?.ratingAverage
                            ? BlueStar
                            : GreyStar
                        }
                        className="carrscontent__reviews_boxleftstar"
                      />
                    );
                  })}
                </div>
              </div>
              <div className="carrscontent__reviews_boxright">
                {ratingDistribution?.map((item, index) => {
                  return (
                    <div className="carrscontent__reviews_britem">
                      <div className="carrscontentr__britem_left">
                        {" "}
                        {[1, 1, 1, 1, 1].map((item, idx) => {
                          return (
                            <img
                              src={5 - index > idx ? BlueStar : GreyStar}
                              className="carrscontentr__britem_leftstar"
                            />
                          );
                        })}
                      </div>
                      <div className="carrscontentr__britem_right">
                        <div className="carrscontentr__britem_rprogress">
                          <div
                            className="carrscontentr__britem_rprogressperc"
                            style={{
                              width: [undefined, null, "NaN", NaN].includes(
                                item
                              )
                                ? "0"
                                : `${item}%`,
                            }}
                          ></div>
                        </div>
                        <div className="carrscontentr__britem_rperc">
                          {[undefined, null, "NaN", NaN].includes(item)
                            ? "0"
                            : item}
                          %
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="carrscontent__reviews_footer">
              <div className="carrscontent__reviews_footerleft">
                <SearchIcon className="carrscontent__search_icon" />
                <input
                  className="carrscontent__search_field"
                  type="text"
                  placeholder="Search Reviews"
                />
              </div>
              <div className="carrscontent__reviews_footerright">
                <div
                  className="carrscontent__writereview_btn"
                  onClick={() => setAddReviewModal(true)}
                >
                  Write a review
                </div>
                {!sortModal && (
                  <Sort
                    onClick={() => setSortModal(!sortModal)}
                    className="carrscontent__writereview_sorticon"
                  />
                )}
                {sortModal && (
                  <img
                    src={Close}
                    onClick={() => setSortModal(!sortModal)}
                    className="carrscontent__writereview_sortclsicon"
                  />
                )}
                {sortModal && <SortReviews />}
              </div>
            </div>
            <div className="carrscontent__reviews_list">
              {company?.ratingResponse?.rating?.map((review) => (
                <ReviewItem
                  reviewerName={review?.user?.username}
                  reviewerImage={
                    review?.user?.profile_image
                      ? `${process.env.REACT_APP_IMG_URL}${review?.user?.profile_image}`
                      : null
                  }
                  reviewerType={review?.user?.role}
                  reviewerLocation={review?.user?.city}
                  reviewHeading={review?.user?.username}
                  reviewDesc={review?.comment}
                  reviewDate={review?.created_at?.slice(0, 10)}
                  reviewStars={parseInt(review?.rating)}
                  // reviewImages={[
                  //   "https://content.instructables.com/F1W/TXMX/ILB5MHRY/F1WTXMXILB5MHRY.jpg?auto=webp",
                  //   "https://content.instructables.com/F1W/TXMX/ILB5MHRY/F1WTXMXILB5MHRY.jpg?auto=webp",
                  // ]}
                />
              ))}
              {company?.ratingResponse?.rating?.length < 1 && (
                <div className="carrscontent__reviews_listempty">
                  No Reviews Available
                </div>
              )}
            </div>
            {/* <div className="carrscontent__reviews_pagination">
              <ReviewsPagination current={10} total={11} />
            </div> */}
          </div>
        </div>
      )}
      {!loading &&
        (company?.ratingResponse?.rating_setting?.length < 1 ||
          !company?.ratingResponse?.rating_setting) && (
          <div className="carrier__reviewscreen_contentplaceholder">
            <div>
              <img
                src={NoContent}
                className="carrier__reviewscreen_cntntplaceholderimg"
              />
              <div className="carrier__reviewscreen_cntntplaceholdertxt">
                No Data Available for{" "}
                <span>{company?.ratingResponse?.name ?? "this Carrier"}</span>
              </div>
            </div>
          </div>
        )}
      {loading && (
        <div className="carrier__reviewscreen_contentloaderwrap">
          <div class="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default CarrierReviews;
