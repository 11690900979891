const Countries = [
  {
    name: "Albania",
    code: "AL",
    lat: 41,
    lng: 20,
    phone: "355",
  },
  {
    name: "Algeria",
    code: "DZ",
    lat: 28,
    lng: 3,
    phone: "213",
  },
  {
    name: "American Samoa",
    code: "AS",
    lat: -14.3333,
    lng: -170,
    phone: "1-684",
  },
  {
    name: "Andorra",
    code: "AD",
    lat: 42.5,
    lng: 1.6,
    phone: "376",
  },
  {
    name: "Angola",
    code: "AO",
    lat: -12.5,
    lng: 18.5,
    phone: "244",
  },
  {
    name: "Anguilla",
    code: "AI",
    lat: 18.25,
    lng: -63.1667,
    phone: "1-264",
  },
  {
    name: "Antarctica",
    code: "AQ",
    lat: -90,
    lng: 0,
    phone: "672",
  },
  {
    name: "Antigua and Barbuda",
    code: "AG",
    lat: 17.05,
    lng: -61.8,
    phone: "1-268",
  },
  {
    name: "Argentina",
    code: "AR",
    lat: -34,
    lng: -64,
    phone: "54",
  },
  {
    name: "Armenia",
    code: "AM",
    lat: 40,
    lng: 45,
    phone: "374",
  },
  {
    name: "Aruba",
    code: "AW",
    lat: 12.5,
    lng: -69.9667,
    phone: "297",
  },
  {
    name: "Australia",
    code: "AU",
    lat: -27,
    lng: 133,
    phone: "61",
  },
  {
    name: "Austria",
    code: "AT",
    lat: 47.3333,
    lng: 13.3333,
    phone: "43",
  },
  {
    name: "Azerbaijan",
    code: "AZ",
    lat: 40.5,
    lng: 47.5,
    phone: "994",
  },
  {
    name: "Bahamas",
    code: "BS",
    lat: 24.25,
    lng: -76,
    phone: "1-242",
  },
  {
    name: "Bahrain",
    code: "BH",
    lat: 26,
    lng: 50.55,
    phone: "973",
  },
  {
    name: "Bangladesh",
    code: "BD",
    lat: 24,
    lng: 90,
    phone: "880",
  },
  {
    name: "Barbados",
    code: "BB",
    lat: 13.1667,
    lng: -59.5333,
    phone: "1-246",
  },
  {
    name: "Belarus",
    code: "BY",
    lat: 53,
    lng: 28,
    phone: "375",
  },
  {
    name: "Belgium",
    code: "BE",
    lat: 50.8333,
    lng: 4,
    phone: "32",
  },
  {
    name: "Belize",
    code: "BZ",
    lat: 17.25,
    lng: -88.75,
    phone: "501",
  },
  {
    name: "Benin",
    code: "BJ",
    lat: 9.5,
    lng: 2.25,
    phone: "229",
  },
  {
    name: "Bermuda",
    code: "BM",
    lat: 32.3333,
    lng: -64.75,
    phone: "1-441",
  },
  {
    name: "Bhutan",
    code: "BT",
    lat: 27.5,
    lng: 90.5,
    phone: "975",
  },
  {
    name: "Bolivia, Plurinational State of",
    code: "BO",
    lat: -17,
    lng: -65,
    phone: "591",
  },
  {
    name: "Bosnia and Herzegovina",
    code: "BA",
    lat: 44,
    lng: 18,
    phone: "387",
  },
  {
    name: "Botswana",
    code: "BW",
    lat: -22,
    lng: 24,
    phone: "267",
  },
  {
    name: "Bouvet Island",
    code: "BV",
    lat: -54.4333,
    lng: 3.4,
    phone: "47",
  },
  {
    name: "Brazil",
    code: "BR",
    lat: -10,
    lng: -55,
    phone: "55",
  },
  {
    name: "British Indian Ocean Territory",
    code: "IO",
    lat: -6,
    lng: 71.5,
    phone: "246",
  },
  {
    name: "Brunei Darussalam",
    code: "BN",
    lat: 4.5,
    lng: 114.6667,
    phone: "673",
  },
  {
    name: "Bulgaria",
    code: "BG",
    lat: 43,
    lng: 25,
    phone: "359",
  },
  {
    name: "Burkina Faso",
    code: "BF",
    lat: 13,
    lng: -2,
    phone: "226",
  },
  {
    name: "Burundi",
    code: "BI",
    lat: -3.5,
    lng: 30,
    phone: "257",
  },
  {
    name: "Cambodia",
    code: "KH",
    lat: 13,
    lng: 105,
    phone: "855",
  },
  {
    name: "Cameroon",
    code: "CM",
    lat: 6,
    lng: 12,
    phone: "237",
  },
  {
    name: "Canada",
    code: "CA",
    lat: 60,
    lng: -95,
    phone: "1",
  },
  {
    name: "Cape Verde",
    code: "CV",
    lat: 16,
    lng: -24,
    phone: "238",
  },
  {
    name: "Cayman Islands",
    code: "KY",
    lat: 19.5,
    lng: -80.5,
    phone: "1-345",
  },
  {
    name: "Central African Republic",
    code: "CF",
    lat: 7,
    lng: 21,
    phone: "236",
  },
  {
    name: "Chad",
    code: "TD",
    lat: 15,
    lng: 19,
    phone: "235",
  },
  {
    name: "Chile",
    code: "CL",
    lat: -30,
    lng: -71,
    phone: "56",
  },
  {
    name: "China",
    code: "CN",
    lat: 35,
    lng: 105,
    phone: "86",
  },
  {
    name: "Christmas Island",
    code: "CX",
    lat: -10.5,
    lng: 105.6667,
    phone: "61",
  },
  {
    name: "Cocos (Keeling) Islands",
    code: "CC",
    lat: -12.5,
    lng: 96.8333,
    phone: "61",
  },
  {
    name: "Colombia",
    code: "CO",
    lat: 4,
    lng: -72,
    phone: "57",
  },
  {
    name: "Comoros",
    code: "KM",
    lat: -12.1667,
    lng: 44.25,
    phone: "269",
  },
  {
    name: "Congo",
    code: "CG",
    lat: -1,
    lng: 15,
    phone: "242",
  },
  {
    name: "Congo, the Democratic Republic of the",
    code: "CD",
    lat: 0,
    lng: 25,
    phone: "243",
  },
  {
    name: "Cook Islands",
    code: "CK",
    lat: -21.2333,
    lng: -159.7667,
    phone: "682",
  },
  {
    name: "Costa Rica",
    code: "CR",
    lat: 10,
    lng: -84,
    phone: "506",
  },
  {
    name: "Côte d'Ivoire",
    code: "CI",
    lat: 8,
    lng: -5,
    phone: "225",
  },
  {
    name: "Croatia",
    code: "HR",
    lat: 45.1667,
    lng: 15.5,
    phone: "385",
  },
  {
    name: "Cuba",
    code: "CU",
    lat: 21.5,
    lng: -80,
    phone: "53",
  },
  {
    name: "Cyprus",
    code: "CY",
    lat: 35,
    lng: 33,
    phone: "357",
  },
  {
    name: "Czech Republic",
    code: "CZ",
    lat: 49.75,
    lng: 15.5,
    phone: "420",
  },
  {
    name: "Denmark",
    code: "DK",
    lat: 56,
    lng: 10,
    phone: "45",
  },
  {
    name: "Djibouti",
    code: "DJ",
    lat: 11.5,
    lng: 43,
    phone: "253",
  },
  {
    name: "Dominica",
    code: "DM",
    lat: 15.4167,
    lng: -61.3333,
    phone: "1-767",
  },
  {
    name: "Dominican Republic",
    code: "DO",
    lat: 19,
    lng: -70.6667,
    phone: "1-809",
  },
  {
    name: "Ecuador",
    code: "EC",
    lat: -2,
    lng: -77.5,
    phone: "593",
  },
  {
    name: "Egypt",
    code: "EG",
    lat: 27,
    lng: 30,
    phone: "20",
  },
  {
    name: "El Salvador",
    code: "SV",
    lat: 13.8333,
    lng: -88.9167,
    phone: "503",
  },
  {
    name: "Equatorial Guinea",
    code: "GQ",
    lat: 2,
    lng: 10,
    phone: "240",
  },
  {
    name: "Eritrea",
    code: "ER",
    lat: 15,
    lng: 39,
    phone: "291",
  },
  {
    name: "Estonia",
    code: "EE",
    lat: 59,
    lng: 26,
    phone: "372",
  },
  {
    name: "Ethiopia",
    code: "ET",
    lat: 8,
    lng: 38,
    phone: "251",
  },
  {
    name: "Falkland Islands (Malvinas)",
    code: "FK",
    lat: -51.75,
    lng: -59,
    phone: "500",
  },
  {
    name: "Faroe Islands",
    code: "FO",
    lat: 62,
    lng: -7,
    phone: "298",
  },
  {
    name: "Fiji",
    code: "FJ",
    lat: -18,
    lng: 175,
    phone: "679",
  },
  {
    name: "Finland",
    code: "FI",
    lat: 64,
    lng: 26,
    phone: "358",
  },
  {
    name: "France",
    code: "FR",
    lat: 46,
    lng: 2,
    phone: "33",
  },
  {
    name: "French Guiana",
    code: "GF",
    lat: 4,
    lng: -53,
    phone: "594",
  },
  {
    name: "French Polynesia",
    code: "PF",
    lat: -15,
    lng: -140,
    phone: "689",
  },
  {
    name: "French Southern Territories",
    code: "TF",
    lat: -43,
    lng: 67,
    phone: "262",
  },
  {
    name: "Gabon",
    code: "GA",
    lat: -1,
    lng: 11.75,
    phone: "241",
  },
  {
    name: "Gambia",
    code: "GM",
    lat: 13.4667,
    lng: -16.5667,
    phone: "220",
  },
  {
    name: "Georgia",
    code: "GE",
    lat: 42,
    lng: 43.5,
    phone: "995",
  },
  {
    name: "Germany",
    code: "DE",
    lat: 51,
    lng: 9,
    phone: "49",
  },
  {
    name: "Ghana",
    code: "GH",
    lat: 8,
    lng: -2,
    phone: "233",
  },
  {
    name: "Gibraltar",
    code: "GI",
    lat: 36.1833,
    lng: -5.3667,
    phone: "350",
  },
  {
    name: "Greece",
    code: "GR",
    lat: 39,
    lng: 22,
    phone: "30",
  },
  {
    name: "Greenland",
    code: "GL",
    lat: 72,
    lng: -40,
    phone: "299",
  },
  {
    name: "Grenada",
    code: "GD",
    lat: 12.1167,
    lng: -61.6667,
    phone: "1-473",
  },
  {
    name: "Guadeloupe",
    code: "GP",
    lat: 16.25,
    lng: -61.5833,
    phone: "590",
  },
  {
    name: "Guam",
    code: "GU",
    lat: 13.4667,
    lng: 144.7833,
    phone: "1-671",
  },
  {
    name: "Guatemala",
    code: "GT",
    lat: 15.5,
    lng: -90.25,
    phone: "502",
  },
  {
    name: "Guernsey",
    code: "GG",
    lat: 49.5,
    lng: -2.56,
    phone: "44",
  },
  {
    name: "Guinea",
    code: "GN",
    lat: 11,
    lng: -10,
    phone: "224",
  },
  {
    name: "Guinea-Bissau",
    code: "GW",
    lat: 12,
    lng: -15,
    phone: "245",
  },
  {
    name: "Guyana",
    code: "GY",
    lat: 5,
    lng: -59,
    phone: "592",
  },
  {
    name: "Haiti",
    code: "HT",
    lat: 19,
    lng: -72.4167,
    phone: "509",
  },
  {
    name: "Heard Island and McDonald Islands",
    code: "HM",
    lat: -53.1,
    lng: 72.5167,
    phone: "672",
  },
  {
    name: "Holy See (Vatican City State)",
    code: "VA",
    lat: 41.9,
    lng: 12.45,
    phone: "379",
  },
  {
    name: "Honduras",
    code: "HN",
    lat: 15,
    lng: -86.5,
    phone: "504",
  },
  {
    name: "Hong Kong",
    code: "HK",
    lat: 22.25,
    lng: 114.1667,
    phone: "852",
  },
  {
    name: "Hungary",
    code: "HU",
    lat: 47,
    lng: 20,
    phone: "36",
  },
  {
    name: "Iceland",
    code: "IS",
    lat: 65,
    lng: -18,
    phone: "354",
  },
  {
    name: "India",
    code: "IN",
    lat: 20,
    lng: 77,
    phone: "91",
  },
  {
    name: "Indonesia",
    code: "ID",
    lat: -5,
    lng: 120,
    phone: "62",
  },
  {
    name: "Iran, Islamic Republic of",
    code: "IR",
    lat: 32,
    lng: 53,
    phone: "98",
  },
  {
    name: "Iraq",
    code: "IQ",
    lat: 33,
    lng: 44,
    phone: "964",
  },
  {
    name: "Ireland",
    code: "IE",
    lat: 53,
    lng: -8,
    phone: "353",
  },
  {
    name: "Isle of Man",
    code: "IM",
    lat: 54.23,
    lng: -4.55,
    phone: "44",
  },
  {
    name: "Israel",
    code: "IL",
    lat: 31.5,
    lng: 34.75,
    phone: "972",
  },
  {
    name: "Italy",
    code: "IT",
    lat: 42.8333,
    lng: 12.8333,
    phone: "39",
  },
  {
    name: "Jamaica",
    code: "JM",
    lat: 18.25,
    lng: -77.5,
    phone: "1-876",
  },
  {
    name: "Japan",
    code: "JP",
    lat: 36,
    lng: 138,
    phone: "81",
  },
  {
    name: "Jersey",
    code: "JE",
    lat: 49.21,
    lng: -2.13,
    phone: "44",
  },
  {
    name: "Jordan",
    code: "JO",
    lat: 31,
    lng: 36,
    phone: "962",
  },
  {
    name: "Kazakhstan",
    code: "KZ",
    lat: 48,
    lng: 68,
    phone: "7",
  },
  {
    name: "Kenya",
    code: "KE",
    lat: 1,
    lng: 38,
    phone: "254",
  },
  {
    name: "Kiribati",
    code: "KI",
    lat: 1.4167,
    lng: 173,
    phone: "686",
  },
  {
    name: "Korea, Democratic People's Republic of",
    code: "KP",
    lat: 40,
    lng: 127,
    phone: "850",
  },
  {
    name: "Korea, Republic of",
    code: "KR",
    lat: 37,
    lng: 127.5,
    phone: "82",
  },
  {
    name: "Kuwait",
    code: "KW",
    lat: 29.3375,
    lng: 47.6581,
    phone: "965",
  },
  {
    name: "Kyrgyzstan",
    code: "KG",
    lat: 41,
    lng: 75,
    phone: "996",
  },
  {
    name: "Lao People's Democratic Republic",
    code: "LA",
    lat: 18,
    lng: 105,
    phone: "856",
  },
  {
    name: "Latvia",
    code: "LV",
    lat: 57,
    lng: 25,
    phone: "371",
  },
  {
    name: "Lebanon",
    code: "LB",
    lat: 33.8333,
    lng: 35.8333,
    phone: "961",
  },
  {
    name: "Lesotho",
    code: "LS",
    lat: -29.5,
    lng: 28.5,
    phone: "266",
  },
  {
    name: "Liberia",
    code: "LR",
    lat: 6.5,
    lng: -9.5,
    phone: "231",
  },
  {
    name: "Libyan Arab Jamahiriya",
    code: "LY",
    lat: 25,
    lng: 17,
    phone: "218",
  },
  {
    name: "Liechtenstein",
    code: "LI",
    lat: 47.1667,
    lng: 9.5333,
    phone: "423",
  },
  {
    name: "Lithuania",
    code: "LT",
    lat: 56,
    lng: 24,
    phone: "370",
  },
  {
    name: "Luxembourg",
    code: "LU",
    lat: 49.75,
    lng: 6.1667,
    phone: "352",
  },
  {
    name: "Macao",
    code: "MO",
    lat: 22.1667,
    lng: 113.55,
    phone: "853",
  },
  {
    name: "Macedonia, the former Yugoslav Republic of",
    code: "MK",
    lat: 41.8333,
    lng: 22,
    phone: "389",
  },
  {
    name: "Madagascar",
    code: "MG",
    lat: -20,
    lng: 47,
    phone: "261",
  },
  {
    name: "Malawi",
    code: "MW",
    lat: -13.5,
    lng: 34,
    phone: "265",
  },
  {
    name: "Malaysia",
    code: "MY",
    lat: 2.5,
    lng: 112.5,
    phone: "60",
  },
  {
    name: "Maldives",
    code: "MV",
    lat: 3.25,
    lng: 73,
    phone: "960",
  },
  {
    name: "Mali",
    code: "ML",
    lat: 17,
    lng: -4,
    phone: "223",
  },
  {
    name: "Malta",
    code: "MT",
    lat: 35.8333,
    lng: 14.5833,
    phone: "356",
  },
  {
    name: "Marshall Islands",
    code: "MH",
    lat: 9,
    lng: 168,
    phone: "692",
  },
  {
    name: "Martinique",
    code: "MQ",
    lat: 14.6667,
    lng: -61,
    phone: "596",
  },
  {
    name: "Mauritania",
    code: "MR",
    lat: 20,
    lng: -12,
    phone: "222",
  },
  {
    name: "Mauritius",
    code: "MU",
    lat: -20.2833,
    lng: 57.55,
    phone: "230",
  },
  {
    name: "Mayotte",
    code: "YT",
    lat: -12.8333,
    lng: 45.1667,
    phone: "262",
  },
  {
    name: "Mexico",
    code: "MX",
    lat: 23,
    lng: -102,
    phone: "52",
  },
  {
    name: "Micronesia, Federated States of",
    code: "FM",
    lat: 6.9167,
    lng: 158.25,
    phone: "691",
  },
  {
    name: "Moldova, Republic of",
    code: "MD",
    lat: 47,
    lng: 29,
    phone: "373",
  },
  {
    name: "Monaco",
    code: "MC",
    lat: 43.7333,
    lng: 7.4,
    phone: "377",
  },
  {
    name: "Mongolia",
    code: "MN",
    lat: 46,
    lng: 105,
    phone: "976",
  },
  {
    name: "Montenegro",
    code: "ME",
    lat: 42,
    lng: 19,
    phone: "382",
  },
  {
    name: "Montserrat",
    code: "MS",
    lat: 16.75,
    lng: -62.2,
    phone: "1-664",
  },
  {
    name: "Morocco",
    code: "MA",
    lat: 32,
    lng: -5,
    phone: "212",
  },
  {
    name: "Mozambique",
    code: "MZ",
    lat: -18.25,
    lng: 35,
    phone: "258",
  },
  {
    name: "Myanmar",
    code: "MM",
    lat: 22,
    lng: 98,
    phone: "95",
  },
  {
    name: "Namibia",
    code: "NA",
    lat: -22,
    lng: 17,
    phone: "264",
  },
  {
    name: "Nauru",
    code: "NR",
    lat: -0.5333,
    lng: 166.9167,
    phone: "674",
  },
  {
    name: "Nepal",
    code: "NP",
    lat: 28,
    lng: 84,
    phone: "977",
  },
  {
    name: "Netherlands",
    code: "NL",
    lat: 52.5,
    lng: 5.75,
    phone: "31",
  },
  {
    name: "New Caledonia",
    code: "NC",
    lat: -21.5,
    lng: 165.5,
    phone: "687",
  },
  {
    name: "New Zealand",
    code: "NZ",
    lat: -41,
    lng: 174,
    phone: "64",
  },
  {
    name: "Nicaragua",
    code: "NI",
    lat: 13,
    lng: -85,
    phone: "505",
  },
  {
    name: "Niger",
    code: "NE",
    lat: 16,
    lng: 8,
    phone: "227",
  },
  {
    name: "Nigeria",
    code: "NG",
    lat: 10,
    lng: 8,
    phone: "234",
  },
  {
    name: "Niue",
    code: "NU",
    lat: -19.0333,
    lng: -169.8667,
    phone: "683",
  },
  {
    name: "Norfolk Island",
    code: "NF",
    lat: -29.0333,
    lng: 167.95,
    phone: "672",
  },
  {
    name: "Northern Mariana Islands",
    code: "MP",
    lat: 15.2,
    lng: 145.75,
    phone: "1-670",
  },
  {
    name: "Norway",
    code: "NO",
    lat: 62,
    lng: 10,
    phone: "47",
  },
  {
    name: "Oman",
    code: "OM",
    lat: 21,
    lng: 57,
    phone: "968",
  },
  {
    name: "Pakistan",
    code: "PK",
    lat: 30,
    lng: 70,
    phone: "92",
  },
  {
    name: "Palau",
    code: "PW",
    lat: 7.5,
    lng: 134.5,
    phone: "680",
  },
  {
    name: "Palestinian Territory, Occupied",
    code: "PS",
    lat: 32,
    lng: 35.25,
    phone: "970",
  },
  {
    name: "Panama",
    code: "PA",
    lat: 9,
    lng: -80,
    phone: "507",
  },
  {
    name: "Papua New Guinea",
    code: "PG",
    lat: -6,
    lng: 147,
    phone: "675",
  },
  {
    name: "Paraguay",
    code: "PY",
    lat: -23,
    lng: -58,
    phone: "595",
  },
  {
    name: "Peru",
    code: "PE",
    lat: -10,
    lng: -76,
    phone: "51",
  },
  {
    name: "Philippines",
    code: "PH",
    lat: 13,
    lng: 122,
    phone: "63",
  },
  {
    name: "Pitcairn",
    code: "PN",
    lat: -24.7,
    lng: -127.4,
    phone: "870",
  },
  {
    name: "Poland",
    code: "PL",
    lat: 52,
    lng: 20,
    phone: "48",
  },
  {
    name: "Portugal",
    code: "PT",
    lat: 39.5,
    lng: -8,
    phone: "351",
  },
  {
    name: "Puerto Rico",
    code: "PR",
    lat: 18.25,
    lng: -66.5,
    phone: "1",
  },
  {
    name: "Qatar",
    code: "QA",
    lat: 25.5,
    lng: 51.25,
    phone: "974",
  },
  {
    name: "Réunion",
    code: "RE",
    lat: -21.1,
    lng: 55.6,
    phone: "262",
  },
  {
    name: "Romania",
    code: "RO",
    lat: 46,
    lng: 25,
    phone: "40",
  },
  {
    name: "Russian Federation",
    code: "RU",
    lat: 60,
    lng: 100,
    phone: "7",
  },
  {
    name: "Rwanda",
    code: "RW",
    lat: -2,
    lng: 30,
    phone: "250",
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    code: "SH",
    lat: -15.9333,
    lng: -5.7,
    phone: "290",
  },
  {
    name: "Saint Kitts and Nevis",
    code: "KN",
    lat: 17.3333,
    lng: -62.75,
    phone: "1-869",
  },
  {
    name: "Saint Lucia",
    code: "LC",
    lat: 13.8833,
    lng: -61.1333,
    phone: "1-758",
  },
  {
    name: "Saint Pierre and Miquelon",
    code: "PM",
    lat: 46.8333,
    lng: -56.3333,
    phone: "508",
  },
  {
    name: "Saint Vincent and the Grenadines",
    code: "VC",
    lat: 13.25,
    lng: -61.2,
    phone: "1-784",
  },
  {
    name: "Samoa",
    code: "WS",
    lat: -13.5833,
    lng: -172.3333,
    phone: "685",
  },
  {
    name: "San Marino",
    code: "SM",
    lat: 43.7667,
    lng: 12.4167,
    phone: "378",
  },
  {
    name: "Sao Tome and Principe",
    code: "ST",
    lat: 1,
    lng: 7,
    phone: "239",
  },
  {
    name: "Saudi Arabia",
    code: "SA",
    lat: 25,
    lng: 45,
    phone: "966",
  },
  {
    name: "Senegal",
    code: "SN",
    lat: 14,
    lng: -14,
    phone: "221",
  },
  {
    name: "Serbia",
    code: "RS",
    lat: 44,
    lng: 21,
    phone: "381",
  },
  {
    name: "Seychelles",
    code: "SC",
    lat: -4.5833,
    lng: 55.6667,
    phone: "248",
  },
  {
    name: "Sierra Leone",
    code: "SL",
    lat: 8.5,
    lng: -11.5,
    phone: "232",
  },
  {
    name: "Singapore",
    code: "SG",
    lat: 1.3667,
    lng: 103.8,
    phone: "65",
  },
  {
    name: "Slovakia",
    code: "SK",
    lat: 48.6667,
    lng: 19.5,
    phone: "421",
  },
  {
    name: "Slovenia",
    code: "SI",
    lat: 46,
    lng: 15,
    phone: "386",
  },
  {
    name: "Solomon Islands",
    code: "SB",
    lat: -8,
    lng: 159,
    phone: "677",
  },
  {
    name: "Somalia",
    code: "SO",
    lat: 10,
    lng: 49,
    phone: "252",
  },
  {
    name: "South Africa",
    code: "ZA",
    lat: -29,
    lng: 24,
    phone: "27",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    code: "GS",
    lat: -54.5,
    lng: -37,
    phone: "500",
  },
  {
    name: "Spain",
    code: "ES",
    lat: 40,
    lng: -4,
    phone: "34",
  },
  {
    name: "Sri Lanka",
    code: "LK",
    lat: 7,
    lng: 81,
    phone: "94",
  },
  {
    name: "Sudan",
    code: "SD",
    lat: 15,
    lng: 30,
    phone: "249",
  },
  {
    name: "Suriname",
    code: "SR",
    lat: 4,
    lng: -56,
    phone: "597",
  },
  {
    name: "Svalbard and Jan Mayen",
    code: "SJ",
    lat: 78,
    lng: 20,
    phone: "47",
  },
  {
    name: "Swaziland",
    code: "SZ",
    lat: -26.5,
    lng: 31.5,
    phone: "268",
  },
  {
    name: "Sweden",
    code: "SE",
    lat: 62,
    lng: 15,
    phone: "46",
  },
  {
    name: "Switzerland",
    code: "CH",
    lat: 47,
    lng: 8,
    phone: "41",
  },
  {
    name: "Syrian Arab Republic",
    code: "SY",
    lat: 35,
    lng: 38,
    phone: "963",
  },
  {
    name: "Taiwan, Province of China",
    code: "TW",
    lat: 23.5,
    lng: 121,
    phone: "886",
  },
  {
    name: "Tajikistan",
    code: "TJ",
    lat: 39,
    lng: 71,
    phone: "992",
  },
  {
    name: "Tanzania, United Republic of",
    code: "TZ",
    lat: -6,
    lng: 35,
    phone: "255",
  },
  {
    name: "Thailand",
    code: "TH",
    lat: 15,
    lng: 100,
    phone: "66",
  },
  {
    name: "Timor-Leste",
    code: "TL",
    lat: -8.55,
    lng: 125.5167,
    phone: "670",
  },
  {
    name: "Togo",
    code: "TG",
    lat: 8,
    lng: 1.1667,
    phone: "228",
  },
  {
    name: "Tokelau",
    code: "TK",
    lat: -9,
    lng: -172,
    phone: "690",
  },
  {
    name: "Tonga",
    code: "TO",
    lat: -20,
    lng: -175,
    phone: "676",
  },
  {
    name: "Trinidad and Tobago",
    code: "TT",
    lat: 11,
    lng: -61,
    phone: "1-868",
  },
  {
    name: "Tunisia",
    code: "TN",
    lat: 34,
    lng: 9,
    phone: "216",
  },
  {
    name: "Turkey",
    code: "TR",
    lat: 39,
    lng: 35,
    phone: "90",
  },
  {
    name: "Turkmenistan",
    code: "TM",
    lat: 40,
    lng: 60,
    phone: "993",
  },
  {
    name: "Turks and Caicos Islands",
    code: "TC",
    lat: 21.75,
    lng: -71.5833,
    phone: "1-649",
  },
  {
    name: "Tuvalu",
    code: "TV",
    lat: -8,
    lng: 178,
    phone: "688",
  },
  {
    name: "Uganda",
    code: "UG",
    lat: 1,
    lng: 32,
    phone: "256",
  },
  {
    name: "Ukraine",
    code: "UA",
    lat: 49,
    lng: 32,
    phone: "380",
  },
  {
    name: "United Arab Emirates",
    code: "AE",
    lat: 24,
    lng: 54,
    phone: "971",
  },
  {
    name: "United Kingdom",
    code: "GB",
    lat: 54,
    lng: -2,
    phone: "44",
  },
  {
    name: "United States",
    code: "US",
    lat: 38,
    lng: -97,
    phone: "1",
  },
  {
    name: "Uruguay",
    code: "UY",
    lat: -33,
    lng: -56,
    phone: "598",
  },
  {
    name: "Uzbekistan",
    code: "UZ",
    lat: 41,
    lng: 64,
    phone: "998",
  },
  {
    name: "Vanuatu",
    code: "VU",
    lat: -16,
    lng: 167,
    phone: "678",
  },
  {
    name: "Venezuela, Bolivarian Republic of",
    code: "VE",
    lat: 8,
    lng: -66,
    phone: "58",
  },
  {
    name: "Viet Nam",
    code: "VN",
    lat: 16,
    lng: 106,
    phone: "84",
  },
  {
    name: "Virgin Islands, British",
    code: "VG",
    lat: 18.5,
    lng: -64.5,
    phone: "1-284",
  },
  {
    name: "Virgin Islands, U.S.",
    code: "VI",
    lat: 18.3333,
    lng: -64.8333,
    phone: "1-340",
  },
  {
    name: "Wallis and Futuna",
    code: "WF",
    lat: -13.3,
    lng: -176.2,
    phone: "681",
  },
  {
    name: "Western Sahara",
    code: "EH",
    lat: 24.5,
    lng: -13,
    phone: "212",
  },
  {
    name: "Yemen",
    code: "YE",
    lat: 15,
    lng: 48,
    phone: "967",
  },
  {
    name: "Zambia",
    code: "ZM",
    lat: -15,
    lng: 30,
    phone: "260",
  },
  {
    name: "Zimbabwe",
    code: "ZW",
    lat: -20,
    lng: 30,
    phone: "263",
  },
  {
    name: "Afghanistan",
    code: "AF",
    lat: 33,
    lng: 65,
    phone: "93",
  },
];

export const countriesMap = {
  AL: {
    name: "Albania",
    code: "AL",
    lat: 41,
    lng: 20,
    phone: "355",
  },
  DZ: {
    name: "Algeria",
    code: "DZ",
    lat: 28,
    lng: 3,
    phone: "213",
  },
  AS: {
    name: "American Samoa",
    code: "AS",
    lat: -14.3333,
    lng: -170,
    phone: "1-684",
  },
  AD: {
    name: "Andorra",
    code: "AD",
    lat: 42.5,
    lng: 1.6,
    phone: "376",
  },
  AO: {
    name: "Angola",
    code: "AO",
    lat: -12.5,
    lng: 18.5,
    phone: "244",
  },
  AI: {
    name: "Anguilla",
    code: "AI",
    lat: 18.25,
    lng: -63.1667,
    phone: "1-264",
  },
  AQ: {
    name: "Antarctica",
    code: "AQ",
    lat: -90,
    lng: 0,
    phone: "672",
  },
  AG: {
    name: "Antigua and Barbuda",
    code: "AG",
    lat: 17.05,
    lng: -61.8,
    phone: "1-268",
  },
  AR: {
    name: "Argentina",
    code: "AR",
    lat: -34,
    lng: -64,
    phone: "54",
  },
  AM: {
    name: "Armenia",
    code: "AM",
    lat: 40,
    lng: 45,
    phone: "374",
  },
  AW: {
    name: "Aruba",
    code: "AW",
    lat: 12.5,
    lng: -69.9667,
    phone: "297",
  },
  AU: {
    name: "Australia",
    code: "AU",
    lat: -27,
    lng: 133,
    phone: "61",
  },
  AT: {
    name: "Austria",
    code: "AT",
    lat: 47.3333,
    lng: 13.3333,
    phone: "43",
  },
  AZ: {
    name: "Azerbaijan",
    code: "AZ",
    lat: 40.5,
    lng: 47.5,
    phone: "994",
  },
  BS: {
    name: "Bahamas",
    code: "BS",
    lat: 24.25,
    lng: -76,
    phone: "1-242",
  },
  BH: {
    name: "Bahrain",
    code: "BH",
    lat: 26,
    lng: 50.55,
    phone: "973",
  },
  BD: {
    name: "Bangladesh",
    code: "BD",
    lat: 24,
    lng: 90,
    phone: "880",
  },
  BB: {
    name: "Barbados",
    code: "BB",
    lat: 13.1667,
    lng: -59.5333,
    phone: "1-246",
  },
  BY: {
    name: "Belarus",
    code: "BY",
    lat: 53,
    lng: 28,
    phone: "375",
  },
  BE: {
    name: "Belgium",
    code: "BE",
    lat: 50.8333,
    lng: 4,
    phone: "32",
  },
  BZ: {
    name: "Belize",
    code: "BZ",
    lat: 17.25,
    lng: -88.75,
    phone: "501",
  },
  BJ: {
    name: "Benin",
    code: "BJ",
    lat: 9.5,
    lng: 2.25,
    phone: "229",
  },
  BM: {
    name: "Bermuda",
    code: "BM",
    lat: 32.3333,
    lng: -64.75,
    phone: "1-441",
  },
  BT: {
    name: "Bhutan",
    code: "BT",
    lat: 27.5,
    lng: 90.5,
    phone: "975",
  },
  BO: {
    name: "Bolivia, Plurinational State of",
    code: "BO",
    lat: -17,
    lng: -65,
    phone: "591",
  },
  BA: {
    name: "Bosnia and Herzegovina",
    code: "BA",
    lat: 44,
    lng: 18,
    phone: "387",
  },
  BW: {
    name: "Botswana",
    code: "BW",
    lat: -22,
    lng: 24,
    phone: "267",
  },
  BV: {
    name: "Bouvet Island",
    code: "BV",
    lat: -54.4333,
    lng: 3.4,
    phone: "47",
  },
  BR: {
    name: "Brazil",
    code: "BR",
    lat: -10,
    lng: -55,
    phone: "55",
  },
  IO: {
    name: "British Indian Ocean Territory",
    code: "IO",
    lat: -6,
    lng: 71.5,
    phone: "246",
  },
  BN: {
    name: "Brunei Darussalam",
    code: "BN",
    lat: 4.5,
    lng: 114.6667,
    phone: "673",
  },
  BG: {
    name: "Bulgaria",
    code: "BG",
    lat: 43,
    lng: 25,
    phone: "359",
  },
  BF: {
    name: "Burkina Faso",
    code: "BF",
    lat: 13,
    lng: -2,
    phone: "226",
  },
  BI: {
    name: "Burundi",
    code: "BI",
    lat: -3.5,
    lng: 30,
    phone: "257",
  },
  KH: {
    name: "Cambodia",
    code: "KH",
    lat: 13,
    lng: 105,
    phone: "855",
  },
  CM: {
    name: "Cameroon",
    code: "CM",
    lat: 6,
    lng: 12,
    phone: "237",
  },
  CA: {
    name: "Canada",
    code: "CA",
    lat: 60,
    lng: -95,
    phone: "1",
  },
  CV: {
    name: "Cape Verde",
    code: "CV",
    lat: 16,
    lng: -24,
    phone: "238",
  },
  KY: {
    name: "Cayman Islands",
    code: "KY",
    lat: 19.5,
    lng: -80.5,
    phone: "1-345",
  },
  CF: {
    name: "Central African Republic",
    code: "CF",
    lat: 7,
    lng: 21,
    phone: "236",
  },
  TD: {
    name: "Chad",
    code: "TD",
    lat: 15,
    lng: 19,
    phone: "235",
  },
  CL: {
    name: "Chile",
    code: "CL",
    lat: -30,
    lng: -71,
    phone: "56",
  },
  CN: {
    name: "China",
    code: "CN",
    lat: 35,
    lng: 105,
    phone: "86",
  },
  CX: {
    name: "Christmas Island",
    code: "CX",
    lat: -10.5,
    lng: 105.6667,
    phone: "61",
  },
  CC: {
    name: "Cocos (Keeling) Islands",
    code: "CC",
    lat: -12.5,
    lng: 96.8333,
    phone: "61",
  },
  CO: {
    name: "Colombia",
    code: "CO",
    lat: 4,
    lng: -72,
    phone: "57",
  },
  KM: {
    name: "Comoros",
    code: "KM",
    lat: -12.1667,
    lng: 44.25,
    phone: "269",
  },
  CG: {
    name: "Congo",
    code: "CG",
    lat: -1,
    lng: 15,
    phone: "242",
  },
  CD: {
    name: "Congo, the Democratic Republic of the",
    code: "CD",
    lat: 0,
    lng: 25,
    phone: "243",
  },
  CK: {
    name: "Cook Islands",
    code: "CK",
    lat: -21.2333,
    lng: -159.7667,
    phone: "682",
  },
  CR: {
    name: "Costa Rica",
    code: "CR",
    lat: 10,
    lng: -84,
    phone: "506",
  },
  CI: {
    name: "Côte d'Ivoire",
    code: "CI",
    lat: 8,
    lng: -5,
    phone: "225",
  },
  HR: {
    name: "Croatia",
    code: "HR",
    lat: 45.1667,
    lng: 15.5,
    phone: "385",
  },
  CU: {
    name: "Cuba",
    code: "CU",
    lat: 21.5,
    lng: -80,
    phone: "53",
  },
  CY: {
    name: "Cyprus",
    code: "CY",
    lat: 35,
    lng: 33,
    phone: "357",
  },
  CZ: {
    name: "Czech Republic",
    code: "CZ",
    lat: 49.75,
    lng: 15.5,
    phone: "420",
  },
  DK: {
    name: "Denmark",
    code: "DK",
    lat: 56,
    lng: 10,
    phone: "45",
  },
  DJ: {
    name: "Djibouti",
    code: "DJ",
    lat: 11.5,
    lng: 43,
    phone: "253",
  },
  DM: {
    name: "Dominica",
    code: "DM",
    lat: 15.4167,
    lng: -61.3333,
    phone: "1-767",
  },
  DO: {
    name: "Dominican Republic",
    code: "DO",
    lat: 19,
    lng: -70.6667,
    phone: "1-809",
  },
  EC: {
    name: "Ecuador",
    code: "EC",
    lat: -2,
    lng: -77.5,
    phone: "593",
  },
  EG: {
    name: "Egypt",
    code: "EG",
    lat: 27,
    lng: 30,
    phone: "20",
  },
  SV: {
    name: "El Salvador",
    code: "SV",
    lat: 13.8333,
    lng: -88.9167,
    phone: "503",
  },
  GQ: {
    name: "Equatorial Guinea",
    code: "GQ",
    lat: 2,
    lng: 10,
    phone: "240",
  },
  ER: {
    name: "Eritrea",
    code: "ER",
    lat: 15,
    lng: 39,
    phone: "291",
  },
  EE: {
    name: "Estonia",
    code: "EE",
    lat: 59,
    lng: 26,
    phone: "372",
  },
  ET: {
    name: "Ethiopia",
    code: "ET",
    lat: 8,
    lng: 38,
    phone: "251",
  },
  FK: {
    name: "Falkland Islands (Malvinas)",
    code: "FK",
    lat: -51.75,
    lng: -59,
    phone: "500",
  },
  FO: {
    name: "Faroe Islands",
    code: "FO",
    lat: 62,
    lng: -7,
    phone: "298",
  },
  FJ: {
    name: "Fiji",
    code: "FJ",
    lat: -18,
    lng: 175,
    phone: "679",
  },
  FI: {
    name: "Finland",
    code: "FI",
    lat: 64,
    lng: 26,
    phone: "358",
  },
  FR: {
    name: "France",
    code: "FR",
    lat: 46,
    lng: 2,
    phone: "33",
  },
  GF: {
    name: "French Guiana",
    code: "GF",
    lat: 4,
    lng: -53,
    phone: "594",
  },
  PF: {
    name: "French Polynesia",
    code: "PF",
    lat: -15,
    lng: -140,
    phone: "689",
  },
  TF: {
    name: "French Southern Territories",
    code: "TF",
    lat: -43,
    lng: 67,
    phone: "262",
  },
  GA: {
    name: "Gabon",
    code: "GA",
    lat: -1,
    lng: 11.75,
    phone: "241",
  },
  GM: {
    name: "Gambia",
    code: "GM",
    lat: 13.4667,
    lng: -16.5667,
    phone: "220",
  },
  GE: {
    name: "Georgia",
    code: "GE",
    lat: 42,
    lng: 43.5,
    phone: "995",
  },
  DE: {
    name: "Germany",
    code: "DE",
    lat: 51,
    lng: 9,
    phone: "49",
  },
  GH: {
    name: "Ghana",
    code: "GH",
    lat: 8,
    lng: -2,
    phone: "233",
  },
  GI: {
    name: "Gibraltar",
    code: "GI",
    lat: 36.1833,
    lng: -5.3667,
    phone: "350",
  },
  GR: {
    name: "Greece",
    code: "GR",
    lat: 39,
    lng: 22,
    phone: "30",
  },
  GL: {
    name: "Greenland",
    code: "GL",
    lat: 72,
    lng: -40,
    phone: "299",
  },
  GD: {
    name: "Grenada",
    code: "GD",
    lat: 12.1167,
    lng: -61.6667,
    phone: "1-473",
  },
  GP: {
    name: "Guadeloupe",
    code: "GP",
    lat: 16.25,
    lng: -61.5833,
    phone: "590",
  },
  GU: {
    name: "Guam",
    code: "GU",
    lat: 13.4667,
    lng: 144.7833,
    phone: "1-671",
  },
  GT: {
    name: "Guatemala",
    code: "GT",
    lat: 15.5,
    lng: -90.25,
    phone: "502",
  },
  GG: {
    name: "Guernsey",
    code: "GG",
    lat: 49.5,
    lng: -2.56,
    phone: "44",
  },
  GN: {
    name: "Guinea",
    code: "GN",
    lat: 11,
    lng: -10,
    phone: "224",
  },
  GW: {
    name: "Guinea-Bissau",
    code: "GW",
    lat: 12,
    lng: -15,
    phone: "245",
  },
  GY: {
    name: "Guyana",
    code: "GY",
    lat: 5,
    lng: -59,
    phone: "592",
  },
  HT: {
    name: "Haiti",
    code: "HT",
    lat: 19,
    lng: -72.4167,
    phone: "509",
  },
  HM: {
    name: "Heard Island and McDonald Islands",
    code: "HM",
    lat: -53.1,
    lng: 72.5167,
    phone: "672",
  },
  VA: {
    name: "Holy See (Vatican City State)",
    code: "VA",
    lat: 41.9,
    lng: 12.45,
    phone: "379",
  },
  HN: {
    name: "Honduras",
    code: "HN",
    lat: 15,
    lng: -86.5,
    phone: "504",
  },
  HK: {
    name: "Hong Kong",
    code: "HK",
    lat: 22.25,
    lng: 114.1667,
    phone: "852",
  },
  HU: {
    name: "Hungary",
    code: "HU",
    lat: 47,
    lng: 20,
    phone: "36",
  },
  IS: {
    name: "Iceland",
    code: "IS",
    lat: 65,
    lng: -18,
    phone: "354",
  },
  IN: {
    name: "India",
    code: "IN",
    lat: 20,
    lng: 77,
    phone: "91",
  },
  ID: {
    name: "Indonesia",
    code: "ID",
    lat: -5,
    lng: 120,
    phone: "62",
  },
  IR: {
    name: "Iran, Islamic Republic of",
    code: "IR",
    lat: 32,
    lng: 53,
    phone: "98",
  },
  IQ: {
    name: "Iraq",
    code: "IQ",
    lat: 33,
    lng: 44,
    phone: "964",
  },
  IE: {
    name: "Ireland",
    code: "IE",
    lat: 53,
    lng: -8,
    phone: "353",
  },
  IM: {
    name: "Isle of Man",
    code: "IM",
    lat: 54.23,
    lng: -4.55,
    phone: "44",
  },
  IL: {
    name: "Israel",
    code: "IL",
    lat: 31.5,
    lng: 34.75,
    phone: "972",
  },
  IT: {
    name: "Italy",
    code: "IT",
    lat: 42.8333,
    lng: 12.8333,
    phone: "39",
  },
  JM: {
    name: "Jamaica",
    code: "JM",
    lat: 18.25,
    lng: -77.5,
    phone: "1-876",
  },
  JP: {
    name: "Japan",
    code: "JP",
    lat: 36,
    lng: 138,
    phone: "81",
  },
  JE: {
    name: "Jersey",
    code: "JE",
    lat: 49.21,
    lng: -2.13,
    phone: "44",
  },
  JO: {
    name: "Jordan",
    code: "JO",
    lat: 31,
    lng: 36,
    phone: "962",
  },
  KZ: {
    name: "Kazakhstan",
    code: "KZ",
    lat: 48,
    lng: 68,
    phone: "7",
  },
  KE: {
    name: "Kenya",
    code: "KE",
    lat: 1,
    lng: 38,
    phone: "254",
  },
  KI: {
    name: "Kiribati",
    code: "KI",
    lat: 1.4167,
    lng: 173,
    phone: "686",
  },
  KP: {
    name: "Korea, Democratic People's Republic of",
    code: "KP",
    lat: 40,
    lng: 127,
    phone: "850",
  },
  KR: {
    name: "Korea, Republic of",
    code: "KR",
    lat: 37,
    lng: 127.5,
    phone: "82",
  },
  KW: {
    name: "Kuwait",
    code: "KW",
    lat: 29.3375,
    lng: 47.6581,
    phone: "965",
  },
  KG: {
    name: "Kyrgyzstan",
    code: "KG",
    lat: 41,
    lng: 75,
    phone: "996",
  },
  LA: {
    name: "Lao People's Democratic Republic",
    code: "LA",
    lat: 18,
    lng: 105,
    phone: "856",
  },
  LV: {
    name: "Latvia",
    code: "LV",
    lat: 57,
    lng: 25,
    phone: "371",
  },
  LB: {
    name: "Lebanon",
    code: "LB",
    lat: 33.8333,
    lng: 35.8333,
    phone: "961",
  },
  LS: {
    name: "Lesotho",
    code: "LS",
    lat: -29.5,
    lng: 28.5,
    phone: "266",
  },
  LR: {
    name: "Liberia",
    code: "LR",
    lat: 6.5,
    lng: -9.5,
    phone: "231",
  },
  LY: {
    name: "Libyan Arab Jamahiriya",
    code: "LY",
    lat: 25,
    lng: 17,
    phone: "218",
  },
  LI: {
    name: "Liechtenstein",
    code: "LI",
    lat: 47.1667,
    lng: 9.5333,
    phone: "423",
  },
  LT: {
    name: "Lithuania",
    code: "LT",
    lat: 56,
    lng: 24,
    phone: "370",
  },
  LU: {
    name: "Luxembourg",
    code: "LU",
    lat: 49.75,
    lng: 6.1667,
    phone: "352",
  },
  MO: {
    name: "Macao",
    code: "MO",
    lat: 22.1667,
    lng: 113.55,
    phone: "853",
  },
  MK: {
    name: "Macedonia, the former Yugoslav Republic of",
    code: "MK",
    lat: 41.8333,
    lng: 22,
    phone: "389",
  },
  MG: {
    name: "Madagascar",
    code: "MG",
    lat: -20,
    lng: 47,
    phone: "261",
  },
  MW: {
    name: "Malawi",
    code: "MW",
    lat: -13.5,
    lng: 34,
    phone: "265",
  },
  MY: {
    name: "Malaysia",
    code: "MY",
    lat: 2.5,
    lng: 112.5,
    phone: "60",
  },
  MV: {
    name: "Maldives",
    code: "MV",
    lat: 3.25,
    lng: 73,
    phone: "960",
  },
  ML: {
    name: "Mali",
    code: "ML",
    lat: 17,
    lng: -4,
    phone: "223",
  },
  MT: {
    name: "Malta",
    code: "MT",
    lat: 35.8333,
    lng: 14.5833,
    phone: "356",
  },
  MH: {
    name: "Marshall Islands",
    code: "MH",
    lat: 9,
    lng: 168,
    phone: "692",
  },
  MQ: {
    name: "Martinique",
    code: "MQ",
    lat: 14.6667,
    lng: -61,
    phone: "596",
  },
  MR: {
    name: "Mauritania",
    code: "MR",
    lat: 20,
    lng: -12,
    phone: "222",
  },
  MU: {
    name: "Mauritius",
    code: "MU",
    lat: -20.2833,
    lng: 57.55,
    phone: "230",
  },
  YT: {
    name: "Mayotte",
    code: "YT",
    lat: -12.8333,
    lng: 45.1667,
    phone: "262",
  },
  MX: {
    name: "Mexico",
    code: "MX",
    lat: 23,
    lng: -102,
    phone: "52",
  },
  FM: {
    name: "Micronesia, Federated States of",
    code: "FM",
    lat: 6.9167,
    lng: 158.25,
    phone: "691",
  },
  MD: {
    name: "Moldova, Republic of",
    code: "MD",
    lat: 47,
    lng: 29,
    phone: "373",
  },
  MC: {
    name: "Monaco",
    code: "MC",
    lat: 43.7333,
    lng: 7.4,
    phone: "377",
  },
  MN: {
    name: "Mongolia",
    code: "MN",
    lat: 46,
    lng: 105,
    phone: "976",
  },
  ME: {
    name: "Montenegro",
    code: "ME",
    lat: 42,
    lng: 19,
    phone: "382",
  },
  MS: {
    name: "Montserrat",
    code: "MS",
    lat: 16.75,
    lng: -62.2,
    phone: "1-664",
  },
  MA: {
    name: "Morocco",
    code: "MA",
    lat: 32,
    lng: -5,
    phone: "212",
  },
  MZ: {
    name: "Mozambique",
    code: "MZ",
    lat: -18.25,
    lng: 35,
    phone: "258",
  },
  MM: {
    name: "Myanmar",
    code: "MM",
    lat: 22,
    lng: 98,
    phone: "95",
  },
  NA: {
    name: "Namibia",
    code: "NA",
    lat: -22,
    lng: 17,
    phone: "264",
  },
  NR: {
    name: "Nauru",
    code: "NR",
    lat: -0.5333,
    lng: 166.9167,
    phone: "674",
  },
  NP: {
    name: "Nepal",
    code: "NP",
    lat: 28,
    lng: 84,
    phone: "977",
  },
  NL: {
    name: "Netherlands",
    code: "NL",
    lat: 52.5,
    lng: 5.75,
    phone: "31",
  },
  NC: {
    name: "New Caledonia",
    code: "NC",
    lat: -21.5,
    lng: 165.5,
    phone: "687",
  },
  NZ: {
    name: "New Zealand",
    code: "NZ",
    lat: -41,
    lng: 174,
    phone: "64",
  },
  NI: {
    name: "Nicaragua",
    code: "NI",
    lat: 13,
    lng: -85,
    phone: "505",
  },
  NE: {
    name: "Niger",
    code: "NE",
    lat: 16,
    lng: 8,
    phone: "227",
  },
  NG: {
    name: "Nigeria",
    code: "NG",
    lat: 10,
    lng: 8,
    phone: "234",
  },
  NU: {
    name: "Niue",
    code: "NU",
    lat: -19.0333,
    lng: -169.8667,
    phone: "683",
  },
  NF: {
    name: "Norfolk Island",
    code: "NF",
    lat: -29.0333,
    lng: 167.95,
    phone: "672",
  },
  MP: {
    name: "Northern Mariana Islands",
    code: "MP",
    lat: 15.2,
    lng: 145.75,
    phone: "1-670",
  },
  NO: {
    name: "Norway",
    code: "NO",
    lat: 62,
    lng: 10,
    phone: "47",
  },
  OM: {
    name: "Oman",
    code: "OM",
    lat: 21,
    lng: 57,
    phone: "968",
  },
  PK: {
    name: "Pakistan",
    code: "PK",
    lat: 30,
    lng: 70,
    phone: "92",
  },
  PW: {
    name: "Palau",
    code: "PW",
    lat: 7.5,
    lng: 134.5,
    phone: "680",
  },
  PS: {
    name: "Palestinian Territory, Occupied",
    code: "PS",
    lat: 32,
    lng: 35.25,
    phone: "970",
  },
  PA: {
    name: "Panama",
    code: "PA",
    lat: 9,
    lng: -80,
    phone: "507",
  },
  PG: {
    name: "Papua New Guinea",
    code: "PG",
    lat: -6,
    lng: 147,
    phone: "675",
  },
  PY: {
    name: "Paraguay",
    code: "PY",
    lat: -23,
    lng: -58,
    phone: "595",
  },
  PE: {
    name: "Peru",
    code: "PE",
    lat: -10,
    lng: -76,
    phone: "51",
  },
  PH: {
    name: "Philippines",
    code: "PH",
    lat: 13,
    lng: 122,
    phone: "63",
  },
  PN: {
    name: "Pitcairn",
    code: "PN",
    lat: -24.7,
    lng: -127.4,
    phone: "870",
  },
  PL: {
    name: "Poland",
    code: "PL",
    lat: 52,
    lng: 20,
    phone: "48",
  },
  PT: {
    name: "Portugal",
    code: "PT",
    lat: 39.5,
    lng: -8,
    phone: "351",
  },
  PR: {
    name: "Puerto Rico",
    code: "PR",
    lat: 18.25,
    lng: -66.5,
    phone: "1",
  },
  QA: {
    name: "Qatar",
    code: "QA",
    lat: 25.5,
    lng: 51.25,
    phone: "974",
  },
  RE: {
    name: "Réunion",
    code: "RE",
    lat: -21.1,
    lng: 55.6,
    phone: "262",
  },
  RO: {
    name: "Romania",
    code: "RO",
    lat: 46,
    lng: 25,
    phone: "40",
  },
  RU: {
    name: "Russian Federation",
    code: "RU",
    lat: 60,
    lng: 100,
    phone: "7",
  },
  RW: {
    name: "Rwanda",
    code: "RW",
    lat: -2,
    lng: 30,
    phone: "250",
  },
  SH: {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    code: "SH",
    lat: -15.9333,
    lng: -5.7,
    phone: "290",
  },
  KN: {
    name: "Saint Kitts and Nevis",
    code: "KN",
    lat: 17.3333,
    lng: -62.75,
    phone: "1-869",
  },
  LC: {
    name: "Saint Lucia",
    code: "LC",
    lat: 13.8833,
    lng: -61.1333,
    phone: "1-758",
  },
  PM: {
    name: "Saint Pierre and Miquelon",
    code: "PM",
    lat: 46.8333,
    lng: -56.3333,
    phone: "508",
  },
  VC: {
    name: "Saint Vincent and the Grenadines",
    code: "VC",
    lat: 13.25,
    lng: -61.2,
    phone: "1-784",
  },
  WS: {
    name: "Samoa",
    code: "WS",
    lat: -13.5833,
    lng: -172.3333,
    phone: "685",
  },
  SM: {
    name: "San Marino",
    code: "SM",
    lat: 43.7667,
    lng: 12.4167,
    phone: "378",
  },
  ST: {
    name: "Sao Tome and Principe",
    code: "ST",
    lat: 1,
    lng: 7,
    phone: "239",
  },
  SA: {
    name: "Saudi Arabia",
    code: "SA",
    lat: 25,
    lng: 45,
    phone: "966",
  },
  SN: {
    name: "Senegal",
    code: "SN",
    lat: 14,
    lng: -14,
    phone: "221",
  },
  RS: {
    name: "Serbia",
    code: "RS",
    lat: 44,
    lng: 21,
    phone: "381",
  },
  SC: {
    name: "Seychelles",
    code: "SC",
    lat: -4.5833,
    lng: 55.6667,
    phone: "248",
  },
  SL: {
    name: "Sierra Leone",
    code: "SL",
    lat: 8.5,
    lng: -11.5,
    phone: "232",
  },
  SG: {
    name: "Singapore",
    code: "SG",
    lat: 1.3667,
    lng: 103.8,
    phone: "65",
  },
  SK: {
    name: "Slovakia",
    code: "SK",
    lat: 48.6667,
    lng: 19.5,
    phone: "421",
  },
  SI: {
    name: "Slovenia",
    code: "SI",
    lat: 46,
    lng: 15,
    phone: "386",
  },
  SB: {
    name: "Solomon Islands",
    code: "SB",
    lat: -8,
    lng: 159,
    phone: "677",
  },
  SO: {
    name: "Somalia",
    code: "SO",
    lat: 10,
    lng: 49,
    phone: "252",
  },
  ZA: {
    name: "South Africa",
    code: "ZA",
    lat: -29,
    lng: 24,
    phone: "27",
  },
  GS: {
    name: "South Georgia and the South Sandwich Islands",
    code: "GS",
    lat: -54.5,
    lng: -37,
    phone: "500",
  },
  ES: {
    name: "Spain",
    code: "ES",
    lat: 40,
    lng: -4,
    phone: "34",
  },
  LK: {
    name: "Sri Lanka",
    code: "LK",
    lat: 7,
    lng: 81,
    phone: "94",
  },
  SD: {
    name: "Sudan",
    code: "SD",
    lat: 15,
    lng: 30,
    phone: "249",
  },
  SR: {
    name: "Suriname",
    code: "SR",
    lat: 4,
    lng: -56,
    phone: "597",
  },
  SJ: {
    name: "Svalbard and Jan Mayen",
    code: "SJ",
    lat: 78,
    lng: 20,
    phone: "47",
  },
  SZ: {
    name: "Swaziland",
    code: "SZ",
    lat: -26.5,
    lng: 31.5,
    phone: "268",
  },
  SE: {
    name: "Sweden",
    code: "SE",
    lat: 62,
    lng: 15,
    phone: "46",
  },
  CH: {
    name: "Switzerland",
    code: "CH",
    lat: 47,
    lng: 8,
    phone: "41",
  },
  SY: {
    name: "Syrian Arab Republic",
    code: "SY",
    lat: 35,
    lng: 38,
    phone: "963",
  },
  TW: {
    name: "Taiwan, Province of China",
    code: "TW",
    lat: 23.5,
    lng: 121,
    phone: "886",
  },
  TJ: {
    name: "Tajikistan",
    code: "TJ",
    lat: 39,
    lng: 71,
    phone: "992",
  },
  TZ: {
    name: "Tanzania, United Republic of",
    code: "TZ",
    lat: -6,
    lng: 35,
    phone: "255",
  },
  TH: {
    name: "Thailand",
    code: "TH",
    lat: 15,
    lng: 100,
    phone: "66",
  },
  TL: {
    name: "Timor-Leste",
    code: "TL",
    lat: -8.55,
    lng: 125.5167,
    phone: "670",
  },
  TG: {
    name: "Togo",
    code: "TG",
    lat: 8,
    lng: 1.1667,
    phone: "228",
  },
  TK: {
    name: "Tokelau",
    code: "TK",
    lat: -9,
    lng: -172,
    phone: "690",
  },
  TO: {
    name: "Tonga",
    code: "TO",
    lat: -20,
    lng: -175,
    phone: "676",
  },
  TT: {
    name: "Trinidad and Tobago",
    code: "TT",
    lat: 11,
    lng: -61,
    phone: "1-868",
  },
  TN: {
    name: "Tunisia",
    code: "TN",
    lat: 34,
    lng: 9,
    phone: "216",
  },
  TR: {
    name: "Turkey",
    code: "TR",
    lat: 39,
    lng: 35,
    phone: "90",
  },
  TM: {
    name: "Turkmenistan",
    code: "TM",
    lat: 40,
    lng: 60,
    phone: "993",
  },
  TC: {
    name: "Turks and Caicos Islands",
    code: "TC",
    lat: 21.75,
    lng: -71.5833,
    phone: "1-649",
  },
  TV: {
    name: "Tuvalu",
    code: "TV",
    lat: -8,
    lng: 178,
    phone: "688",
  },
  UG: {
    name: "Uganda",
    code: "UG",
    lat: 1,
    lng: 32,
    phone: "256",
  },
  UA: {
    name: "Ukraine",
    code: "UA",
    lat: 49,
    lng: 32,
    phone: "380",
  },
  AE: {
    name: "United Arab Emirates",
    code: "AE",
    lat: 24,
    lng: 54,
    phone: "971",
  },
  GB: {
    name: "United Kingdom",
    code: "GB",
    lat: 54,
    lng: -2,
    phone: "44",
  },
  US: {
    name: "United States",
    code: "US",
    lat: 38,
    lng: -97,
    phone: "1",
  },
  UY: {
    name: "Uruguay",
    code: "UY",
    lat: -33,
    lng: -56,
    phone: "598",
  },
  UZ: {
    name: "Uzbekistan",
    code: "UZ",
    lat: 41,
    lng: 64,
    phone: "998",
  },
  VU: {
    name: "Vanuatu",
    code: "VU",
    lat: -16,
    lng: 167,
    phone: "678",
  },
  VE: {
    name: "Venezuela, Bolivarian Republic of",
    code: "VE",
    lat: 8,
    lng: -66,
    phone: "58",
  },
  VN: {
    name: "Viet Nam",
    code: "VN",
    lat: 16,
    lng: 106,
    phone: "84",
  },
  VG: {
    name: "Virgin Islands, British",
    code: "VG",
    lat: 18.5,
    lng: -64.5,
    phone: "1-284",
  },
  VI: {
    name: "Virgin Islands, U.S.",
    code: "VI",
    lat: 18.3333,
    lng: -64.8333,
    phone: "1-340",
  },
  WF: {
    name: "Wallis and Futuna",
    code: "WF",
    lat: -13.3,
    lng: -176.2,
    phone: "681",
  },
  EH: {
    name: "Western Sahara",
    code: "EH",
    lat: 24.5,
    lng: -13,
    phone: "212",
  },
  YE: {
    name: "Yemen",
    code: "YE",
    lat: 15,
    lng: 48,
    phone: "967",
  },
  ZM: {
    name: "Zambia",
    code: "ZM",
    lat: -15,
    lng: 30,
    phone: "260",
  },
  ZW: {
    name: "Zimbabwe",
    code: "ZW",
    lat: -20,
    lng: 30,
    phone: "263",
  },
  AF: {
    name: "Afghanistan",
    code: "AF",
    lat: 33,
    lng: 65,
    phone: "93",
  },
};

export default Countries;
