import React from "react";
import { useState } from "react";
import "./FeatureTab.css";
import checkicon from "./images/check.png";
import storeconnectimg from "./images/storeconnectimg.png";
import trackingcap from "./images/Tracking-Capabilities.png";
import MarketPlaces from "./images/MarketPlaces.jpg";
import zerofreeplan from "./images/Zero-Free-Plan.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const FeatureTab = () => {
  const [show, setshow] = useState("1");

  return (
    <section className="featuretab__section_wrap" id="featuretab__section_wrap">
      <div className="featuretab__heading_wrap">
        <h2>
          Your Powerful <span>Ecommerce Shipping</span> Solution
        </h2>
        <h3>
          Some <span>prominent</span> features of Bolt:
        </h3>
      </div>

      <div className="featuretab__tab_wrap">
        <div className="featuretab__btn_row">
          <button
            className={
              show === "1" ? "featuretab__btn active" : "featuretab__btn"
            }
            onClick={() => setshow("1")}
          >
            Connect Your Store
          </button>
          <button
            className={
              show === "2" ? "featuretab__btn active" : "featuretab__btn"
            }
            onClick={() => setshow("2")}
          >
            Tracking Capabilities
          </button>
          <button
            className={
              show === "3" ? "featuretab__btn active" : "featuretab__btn"
            }
            onClick={() => setshow("3")}
          >
            Free Plugins
          </button>
        </div>
        <div
          className={
            show === "1"
              ? "featuretab__tabcontent_wrap active"
              : "featuretab__tabcontent_wrap"
          }
          tab={show === "1" ? "1" : " "}
        >
          <div className="featuretab__tabcontent_leftcol">
            <ul className="customcarrier__list_wrap">
              <li className="customcarrier__list">
                <LazyLoadImage src={checkicon} alt="-" />
                Simplify store integration with our free plugins for Woocommerce
                and Shopify - connect effortlessly in just a few clicks!
              </li>
              <li className="customcarrier__list">
                <LazyLoadImage src={checkicon} alt="-" />
                Effortlessly connect your marketplace accounts (Amazon, Walmart,
                eBay, Best Buy, etc.) and seamlessly manage orders, accounting,
                and shipping in real time. Streamline your operations with ease!
              </li>
              <li className="customcarrier__list">
                <LazyLoadImage src={checkicon} alt="-" />
                Our solution enables you to bring all your marketplace orders
                into a centralized platform, simplifying your order management
                process. Experience efficient multi-channel selling today!
              </li>
              <li className="customcarrier__list">
                <LazyLoadImage src={checkicon} alt="-" />
                Unlock a plethora of additional features and enhance your
                e-commerce experience to the fullest!
              </li>
            </ul>
          </div>
          <div className="featuretab__tabcontent_rightcol">
            <LazyLoadImage src={storeconnectimg} alt="-" />
          </div>
        </div>
        <div
          className={
            show === "2"
              ? "featuretab__tabcontent_wrap active"
              : "featuretab__tabcontent_wrap"
          }
          tab={show === "2" ? "2" : " "}
        >
          <div className="featuretab__tabcontent_leftcol">
            <ul className="customcarrier__list_wrap">
              <li className="customcarrier__list">
                <LazyLoadImage src={checkicon} alt="-" />
                Track your packages/shipments using carrier provided tracking
                codes or use our unique tracking codes to ease and provide
                better tracking experience to your customers.
              </li>
              <li className="customcarrier__list">
                <LazyLoadImage src={checkicon} alt="-" />
                Categorize your shipments and tracking codes according to
                marketplace and carrier
              </li>
              <li className="customcarrier__list">
                <LazyLoadImage src={checkicon} alt="-" />
                Simply paste HAJEX provided unique tracking code on google and
                find easily the tracking page along with carrier tracking code.
              </li>
              <li className="customcarrier__list">
                <LazyLoadImage src={checkicon} alt="-" /> Enjoy many more
                features
              </li>
            </ul>
          </div>
          <div className="featuretab__tabcontent_rightcol">
            <LazyLoadImage src={MarketPlaces} alt="-" />
          </div>
        </div>
        <div
          className={
            show === "3"
              ? "featuretab__tabcontent_wrap active"
              : "featuretab__tabcontent_wrap"
          }
          tab={show === "3" ? "3" : " "}
        >
          <div className="featuretab__tabcontent_leftcol">
            <ul className="customcarrier__list_wrap">
              <li className="customcarrier__list">
                <LazyLoadImage src={checkicon} alt="-" />
                Our plugins come with "Free Forever" plan to help Small
                Businesses.
              </li>
              <li className="customcarrier__list">
                <LazyLoadImage src={checkicon} alt="-" />
                No Monthly fee so you can focus on your business without
                worrying about the bills
              </li>
              <li className="customcarrier__list">
                <LazyLoadImage src={checkicon} alt="-" /> Enjoy many more
                features
              </li>
            </ul>
          </div>
          <div className="featuretab__tabcontent_rightcol">
            <LazyLoadImage src={zerofreeplan} alt="-" />
          </div>
        </div>
      </div>

      <div className="featuretab__mobileview_wrap">
        <div className="featuretab__firsttab_sec">
          <h3>Connect Your Store</h3>
          <ul className="customcarrier__list_wrap">
            <li className="customcarrier__list">
              <LazyLoadImage src={checkicon} alt="-" />
              Simplify store integration with our free plugins for Woocommerce
              and Shopify - connect effortlessly in just a few clicks!
            </li>
            <li className="customcarrier__list">
              <LazyLoadImage src={checkicon} alt="-" />
              Effortlessly connect your marketplace accounts (Amazon, Walmart,
              eBay, Best Buy, etc.) and seamlessly manage orders, accounting,
              and shipping in real time. Streamline your operations with ease!
            </li>
            <li className="customcarrier__list">
              <LazyLoadImage src={checkicon} alt="-" />
              Our solution enables you to bring all your marketplace orders into
              a centralized platform, simplifying your order management process.
              Experience efficient multi-channel selling today!
            </li>
            <li className="customcarrier__list">
              <LazyLoadImage src={checkicon} alt="-" />
              Unlock a plethora of additional features and enhance your
              e-commerce experience to the fullest!
            </li>
          </ul>
          <LazyLoadImage
            src={storeconnectimg}
            className="featuretab__tabimg"
            alt="-"
          />
        </div>
        <div className="featuretab__secondtab_sec">
          <h3>Tracking Capabilities</h3>
          <ul className="customcarrier__list_wrap">
            <li className="customcarrier__list">
              <LazyLoadImage src={checkicon} alt="-" />
              Track your packages/shipments using carrier provided tracking
              codes or use our unique tracking codes to ease and provide better
              tracking experience to your customers.
            </li>
            <li className="customcarrier__list">
              <LazyLoadImage src={checkicon} alt="-" />
              Categorize your shipments and tracking codes according to
              marketplace and carrier
            </li>
            <li className="customcarrier__list">
              <LazyLoadImage src={checkicon} alt="-" />
              Simply paste HAJEX provided unique tracking code on google and
              find easily the tracking page along with carrier tracking code.
            </li>
            <li className="customcarrier__list">
              <LazyLoadImage src={checkicon} alt="-" /> Enjoy many more features
            </li>
          </ul>
          <LazyLoadImage
            src={MarketPlaces}
            className="featuretab__tabimg"
            alt="-"
          />
        </div>
        <div className="featuretab__thirdtab_sec">
          <h3>Free Plugins</h3>
          <ul className="customcarrier__list_wrap">
            <li className="customcarrier__list">
              <LazyLoadImage src={checkicon} alt="-" />
              Our plugins offer the capability to integrate your Shopify,
              Walmart, and Amazon stores seamlessly in one centralized location.
            </li>
            <li className="customcarrier__list">
              <LazyLoadImage src={checkicon} alt="-" />
              No Monthly fee so you can focus on your business without worrying
              about the bills
            </li>
            <li className="customcarrier__list">
              <LazyLoadImage src={checkicon} alt="-" /> Enjoy many more features
            </li>
          </ul>
          <LazyLoadImage
            src={zerofreeplan}
            className="featuretab__tabimg"
            alt="-"
          />
        </div>
      </div>
    </section>
  );
};

export default FeatureTab;
