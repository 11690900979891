import  { lazy, React } from "react";
import { Helmet } from 'react-helmet';
import Artboard from "../../assets/images/artboard.webp";
import Story from "../../assets/images/story-image.png";
import Map from "../../assets/images/map.png";
import Happy from "../../assets/icons/happy-face.svg";
import Years from "../../assets/icons/10-years.svg";
import Fast from "../../assets/icons/fast.svg";
import Planet from "../../assets/icons/planet.svg";

import "./About.css";

import loadable from '@loadable/component'
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Header = loadable(() => import('../../Components/Header/Header'));
const Footer = loadable(() => import('../../Components/Footer/Footer'));
const AnyQuestion = loadable(() => import('../../Components/AnyQuestion/AnyQuestion') ) ;
const FactsCard = loadable(() => import('../../Components/FactsCard/FactsCard') ) ;


const TITLE = 'About'; 

const About = () => {
  return (
    <div className="about__screen">
       <Helmet>
        <title>Hajex Bolt | { TITLE } </title>
          <meta name="theme-color" content="#000000" />
          <meta name="description" content="At Hajex, we are a leading carrier service provider dedicated to simplifying store integration and revolutionizing the shipping experience for ecommerce businesses. With our free plugins for Woocommerce and Shopify, you can effortlessly connect your store in just a few clicks."/>
          <meta name="keywords" content="Carrier Service Provider, Store Integration, Shipping Solutions, Woocommerce, Shopify, Tracking Codes, Better Customer Experience"/>
          <meta name="author" content="Hajex"/>
          <meta property="og:title" content="About Hajex - Simplifying Store Integration and Shipping Solutions"/>
          <meta property="og:description" content="Hajex is a trusted carrier service provider, offering seamless store integration and comprehensive shipping solutions for ecommerce businesses. Our free plugins for Woocommerce and Shopify enable you to connect your store effortlessly, streamlining the entire shipping process."/>
          <meta property="og:image" content="%PUBLIC_URL%/favicon.ico"/>
          <meta property="og:url" content="https://hajexbolt.com/about"/>
          <link rel="apple-touch-icon" href="%PUBLIC_URL%/favicon.ico" />
      </Helmet>
      <Header />
      <div className="about__screen_banner">
        <LazyLoadImage src={Artboard} className="abouts__banner_artboard" alt="-"/>
        <div className="abtscreen__banner_title">
          <span>Home</span>
          <span>{`>`}</span>
          <span className="abtscreen__banner_titleactive">About</span>
        </div>
        <div className="abtscreen__banner_heading">About Us</div>
      </div>
      <section className="about__screen_storysec">
        <div className="about__screen_storysecinnerwrap">
          <div className="abtscreen_storysec_left">
            <div className="abtscreen_storysec_leftheading">Our Story</div>
            <div className="abtscreen_storysec_leftdesc">
              HAJEX is a Federally and Provincially registered Canadian
              Corporation having registed tradename with CIPO - Canadian
              Intellectual Property Office.We specialize in technology,
              logistics, transportation, information management, e-commerce,
              manufacturing and various other Industries. Having experience for
              years as a Retailer and Manufacturer in Canada we had wide B2C and
              B2B experience from where we identified flaws and weaknesses in
              our Logistics and Supply chain where some players get unfair
              advantages over others and we are here to balance the scale and
              put more in the pockets of hard working Canadians. HAJEX was able
              to create many technological advancements and will continue to do
              so to improve and to contribute to Logistics Worldwide.
            </div>
          </div>
          <div className="abtscreen_storysec_right">
            <div className="abtscreen_storysec_rightimgwrap">
              <LazyLoadImage src={Story} alt="" className="abtscreen_storysec_rightimg" width="601" height="535"/>
            </div>
          </div>
        </div>
      </section>
      <div className="abtscreen__map_imgwrap">
        <LazyLoadImage src={Map} alt="" className="abtscreen__map_img" />
      </div>
      <div className="abtscreen__map_facts">
        <div className="abtscreen__map_factsheading">
          Some Of Our &nbsp;<span>Facts</span>
        </div>
        <div className="abtscreen__map_factscontent">
          <FactsCard
            icon={<LazyLoadImage src={Happy} alt="" />}
            title="5000+"
            heading="Happy Customers"
            subheading="(with HAJEX)"
          />
          <FactsCard
            icon={<LazyLoadImage src={Fast} alt="" />}
            title="99.95%"
            heading="Shipment Fulfilled"
          />
          <FactsCard
            icon={<LazyLoadImage src={Years} alt="" />}
            title="10+"
            heading="Years of Experience"
          />
          <FactsCard
            icon={<LazyLoadImage src={Planet} alt="" />}
            title="150+"
            heading="Countries Reached"
          />
        </div>
      </div>
      <AnyQuestion />
      <Footer />
    </div>
  );
};

export default About;
