import React from "react";
import { useEffect, useState } from "react";
import Canpar from "../../assets/images/canpar.png";
import "./Tracking.css";
import axios from "axios";

import Snackbar from "../../Components/SnackBar/SnackBar";
import CircularProgress from "@mui/material/CircularProgress";
import Form from "react-bootstrap/Form";

import image1 from "./images/canpar.png";
import image2 from "./images/daysnross.png";
import image3 from "./images/dhl.png";
import image4 from "./images/canadapostes.png";
import image5 from "./images/fedex.png";
import image6 from "./images/gls.png";
import image7 from "./images/ics.png";
import image8 from "./images/unitedstatespostalservice.png";
// import image9 from "./images/ups.png";
import image10 from "./images/vitran.png";
import { useNavigate } from "react-router-dom";
import Artboard from "../../assets/images/artboard.webp";
import loadable from "@loadable/component";
import { LazyLoadImage } from "react-lazy-load-image-component";
import HelmetComponent from "./Components/Helmet";
import InquireyComponent from "./Components/Inquirey";
import ShipmentHistory from "./Components/ShipmentHistory";
import StatusIcons from "../../Components/StatusIcons/StatusIcons";

const Header = loadable(() => import("../../Components/Header/Header"));
const Footer = loadable(() => import("../../Components/Footer/Footer"));

const images = [
  { image: image1, alt: "canpar hajex bolt" },
  { image: image2, alt: "daysnross hajex bolt" },
  { image: image3, alt: "dhl hajex bolt" },
  { image: image4, alt: "canadapostes" },
  { image: image5, alt: "fedex" },
  { image: image6, alt: "gls" },
  { image: image7, alt: "ics" },
  { image: image8, alt: "united states postal service" },
  // {image: image9, alt: "ups"},
  { image: image10, alt: "vitran" },
];

const Tracking = () => {
  const [hometracking, sethometracking] = useState(true);
  const [Tracking_Id, setTracking_Id] = useState("");
  const [trackingresponse, settrackingresponse] = useState("");
  const [trackingpackages, settrackingpackages] = useState("");
  const [trackingstatus, settrackingstatus] = useState("");
  const [loading, setLoading] = useState();
  const navigate = useNavigate();

  const [snack, setSnack] = useState({
    open: false,
    vertical: "top",
    horizontal: "right",
    text: "",
    severity: "",
  });

  // Modify the URL with tracking Id
  const queryParams = new URLSearchParams(window.location.search);
  const trackingId = queryParams.get("tracking_id");

  useEffect(() => {
    const handleTrackingSubmit = async () => {
      setLoading(true);
      try {
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_API_URL}landing-page/get-shipment-by-tracking-id`,
          data: {
            Tracking_Id: trackingId,
          },
        });
        if (response.data.status === true) {
          settrackingresponse(response.data.data[0]);
          settrackingpackages(response.data.data[0].shipment_details);
          settrackingstatus(response.data.data[0].shipment_histories);
          sethometracking(false);
          setSnack({
            ...snack,
            open: true,
            severity: "success",
            text: response.data.message,
          });
        } else {
          setSnack({
            ...snack,
            open: true,
            severity: "error",
            text: response.data.message,
          });
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setSnack({
          ...snack,
          open: true,
          severity: "error",
          text: "Invalid Tracking Id",
        });
      }
    };

    if (trackingId) {
      handleTrackingSubmit();
    } else {
      //  console.log("no tracking id");
    }
  }, [trackingId]);

  const handleTrackingSubmit = async (event) => {
    event.preventDefault();
    navigate({
      pathname: "/tracking",
      search: `?tracking_id=${Tracking_Id}`,
    });
  };

  return (
    <div className="tracking__screen_wrap">
      <HelmetComponent />
      <Snackbar Snack={snack} SetSnack={setSnack} />
      <Header />
      <div className="about__screen_banner">
        <LazyLoadImage
          src={Artboard}
          className="abouts__banner_artboard"
          alt="-"
        />
        <div className="abtscreen__banner_heading">Tracking</div>
      </div>
      {loading && (
        <div className="abtscreen__banner_loading">
          <CircularProgress />
        </div>
      )}
      {!loading && hometracking === false && (
        <>
          <div className="packagedetails__main_header_content">
            <div className="packagedetails__content_wrap">
              <h2>
                {" "}
                Shipment Current Status:{" "}
                <span
                  className={
                    trackingresponse["shipment_status"]
                      .toLowerCase()
                      .replace(/ /g, "") + "-class"
                  }
                >
                  {" "}
                  {trackingresponse?.shipment_status ?? "-"}{" "}
                </span>{" "}
              </h2>
              <h4>
                Estimate Delivery:{" "}
                <span
                  className={
                    trackingresponse["shipment_status"]
                      .toLowerCase()
                      .replace(/ /g, "") + "-class"
                  }
                >
                  {trackingresponse?.estimated_delivery_date.split("T")[0] ??
                    "-"}{" "}
                </span>{" "}
              </h4>
            </div>
            <div className="packagedetails__button_wrap">
              <button className="packagedetails__button_reviewbtn">
                Leave a Review
              </button>
              <InquireyComponent />
            </div>
          </div>

          <ShipmentHistory trackingstatus={trackingstatus} />

          <div
            className={
              trackingresponse["shipment_status"]
                .toLowerCase()
                .replace(/ /g, "") + "-class packagedetails__main_wrap"
            }
          >
            <div className="packagedetails__parcelDetails">
              <h3 className="packagedetails__parcelDetails_heading">
                Package Details
              </h3>

              <div className="packagedetails__list_wrapper">
                <div className="packagedetails__list_header_wrap">
                  <div className="packagedetails__list_header_row">
                    <div className="packagedetails__list_header_index">#</div>
                    <div className="packagedetails__list_header_lastupdate">
                      Parcel ID
                    </div>
                    <div className="packagedetails__list_header_laststatus">
                      Last Status{" "}
                    </div>
                    <div className="packagedetails__list_header_description">
                      Description
                    </div>
                    <div className="packagedetails__list_header_size">Size</div>
                    <div className="packagedetails__list_header_weight">
                      Weight
                    </div>
                    <div className="packagedetails__list_header_barcode">
                      Last Update
                    </div>
                  </div>
                </div>
                {trackingpackages.map((item, index) => (
                  <div key={index} className="packagedetails__list_wrap">
                    <div className="packagedetails__list_index" data-field="#">
                      {index + 1}
                    </div>
                    <div
                      className="packagedetails__list_lastupdate"
                      data-field="Barcode"
                    >
                      {" "}
                      <span>{item?.parcel_id ?? "-"}</span>
                    </div>
                    <div
                      className="packagedetails__list_laststatus"
                      data-field="Last Status"
                    >
                      <StatusIcons status={item?.tracking_status ?? "N/A"} />
                    </div>
                    <div
                      className="packagedetails__list_description"
                      data-field="Description"
                    >
                      <span>{item?.description ?? "-"}</span>
                    </div>
                    <div
                      className="packagedetails__list_size"
                      data-field="Size"
                    >
                      {" "}
                      <span>
                        {item?.length ?? "-"}x{item?.width ?? "-"}x
                        {item?.height ?? "-"}
                      </span>
                    </div>
                    <div
                      className="packagedetails__list_weight"
                      data-field="Weight"
                    >
                      {" "}
                      <span>{item?.weight ?? "-"}lb</span>
                    </div>
                    <div
                      className="packagedetails__list_barcode"
                      data-field="Last Update"
                    >
                      {" "}
                      <span>{item?.updated_at.split("T")[0] ?? "-"}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <h3 className="packagedetails__parcelDetails_heading">
              Overall Details
            </h3>

            <div className="packagedetails__overallDetails_main_wrapper">
              <div className="packagedetails__overallDetails_master">
                <div className="packagedetails__list_carrierlogo">
                  <span>Carrier</span>{" "}
                  <LazyLoadImage
                    src={
                      trackingresponse["shipping_company"]["shipping_company"][
                        "logo_url"
                      ]
                    }
                    alt="-"
                  />{" "}
                </div>
                <div className="packagedetails__list_pickup">
                  <span>Carrier Name</span>{" "}
                  {trackingresponse?.single_shipping_labels?.carrier ?? "-"}
                </div>
                <div className="packagedetails__list_pickup">
                  <span>Pickup#</span> {trackingresponse?.PickupID ?? "-"}
                </div>
                <div className="packagedetails__list_mastertracking">
                  <span>Master Tracking</span>
                  {trackingresponse?.shipment_tracking_id ?? "-"}
                </div>
                <div className="packagedetails__list_carriertracking">
                  <span>Carrier Tracking</span>{" "}
                  {trackingresponse?.shipment_tracking_id ?? "-"}
                </div>
              </div>
              <div className="packagedetails__overallDetails_overall">
                <div className="packagedetails__list_shipmentbooked">
                  <span>Shipment Booked</span>
                  {trackingresponse?.created_at.split("T")[0] ?? "-"}
                </div>
                <div className="packagedetails__list_shipmentbooked">
                  <span>Pickup City</span>
                  {trackingresponse?.fcity ?? "-"}
                </div>
                <div className="packagedetails__list_shipmentbooked">
                  <span>Delivery City</span>
                  {trackingresponse?.tcity ?? "-"}
                </div>
                <div className="packagedetails__list_packagecount">
                  <span>Package Count</span> {trackingpackages?.length ?? "-"}{" "}
                </div>
                <div className="packagedetails__list_overallweight">
                  <span>Overall Weight</span>{" "}
                  {trackingresponse?.total_weight ?? "-"}lb
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {!loading && hometracking === true && (
        <>
          <div className="tracking__screen_content">
            <div className="tracking__screen_form_wrap">
              <h3 className="tracking__screen_carrier_listing_heading">
                {" "}
                Track From Our 62+ Carriers{" "}
              </h3>
              <Form
                onSubmit={handleTrackingSubmit}
                className="tracking__screen_form"
              >
                <input
                  type="text"
                  placeholder="Enter the tracking ID"
                  value={Tracking_Id}
                  onChange={(event) => setTracking_Id(event.target.value)}
                  required
                  className="tracking__screen_formfield"
                />
                <button
                  className="tracking__screen_formbtn"
                  id="Tracking_Id"
                  type="submit"
                >
                  {" "}
                  TRACK
                </button>
              </Form>
              <ul className="tracking__screen_carriers_list">
                {images.map((item, index) => (
                  <li key={index}>
                    <LazyLoadImage src={item.image} alt={item.alt} />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </>
      )}
      <Footer />
    </div>
  );
};

export default Tracking;
