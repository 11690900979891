import React, { useState } from "react";
import arrowDown from "../../Landingpage/images/downArrow.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Dropdown() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const navigateToLogin = (url) => {
    window.location.href = url;
  };

  return (
    <div className="header__dropdown_wrap">
      <button
        type="button"
        className="header__dropdown_btn"
        onClick={() => navigateToLogin("https://ship.hajexbolt.com/Login")}
      >
        Login
      </button>
      <button
        type="button"
        className="header__dropdown_btn"
        onClick={() => navigateToLogin("https://ship.hajexbolt.com/Register")}
      >
        Register
      </button>
      {/* {isOpen && (
        <div className="header__dropdown_menu">
          <a
            className="header__dropdown_item"
            href="https://ship.hajexbolt.com/Login"
          >
            Login
          </a>
          <a
            className="header__dropdown_item"
            href="https://legacy.hajexbolt.com"
          >
            Legacy Login
          </a>
          <a
            className="header__dropdown_item"
            href="https://ship.hajexbolt.com/Register"
          >
            Register
          </a>
        </div>
      )} */}
    </div>
  );
}

export default Dropdown;
