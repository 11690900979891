import React, { useState } from "react";
import { Link } from "react-router-dom";
import LocationMarker from "../../../assets/icons/location-marker.svg";
import BlueStar from "../../../assets/icons/blue-star.svg";
import GreyStar from "../../../assets/icons/grey-star.svg";
import { ReactComponent as ThumbsUp } from "../../../assets/icons/thumbs-up.svg";
import { ReactComponent as ThumbsDown } from "../../../assets/icons/thumbs-down.svg";
import "./ReviewItem.css";

const ReviewItem = ({
  reviewerName,
  reviewerType,
  reviewerLocation,
  reviewHeading,
  reviewDesc,
  reviewDate,
  reviewStars,
  reviewImages,
  reviewerImage,
}) => {
  const [liked, setLiked] = useState("");
  return (
    <div className="review__item_wrap">
      <div className="review__item_body">
        <div className="review__item_reviewerinfo">
          <div className="review__item_reviewerimg">
            <img
              src={
                reviewerImage ??
                "https://st.depositphotos.com/2101611/4338/v/600/depositphotos_43381243-stock-illustration-male-avatar-profile-picture.jpg"
              }
              onError={(e) => {
                e.target.src =
                  "https://st.depositphotos.com/2101611/4338/v/600/depositphotos_43381243-stock-illustration-male-avatar-profile-picture.jpg";
              }}
            />
          </div>
          <div className="review__item_reviewername">
            {reviewerName?.slice(0, 10)}
            {reviewerName?.length > 10 && "..."}
          </div>
          <Link className="review__item_reviewertype">{reviewerType}</Link>
          <div className="review__item_reviewerlocation">
            <img
              src={LocationMarker}
              className="review__item_reviewerlocationmark"
            />
            <span className="review__item_reviewerlocationtxt">
              {reviewerLocation?.slice(0, 10)}
              {reviewerLocation?.length > 10 && "..."}
            </span>
          </div>
        </div>
        <div className="review__item_reviewinfo">
          <div className="review__item_reviewheading">{reviewHeading}</div>
          <div className="review__item_reviewdesc">{reviewDesc}</div>
          <div className="review__item_reviewimages">
            {reviewImages?.map((image) => {
              return (
                <div className="review__item_reviewimage">
                  <img src={image} />
                </div>
              );
            })}
          </div>
        </div>
        <div className="review__item_rightsec">
          <div className="review__item_rightsecdate">{reviewDate}</div>
          <div className="review__item_rightsecstars">
            {" "}
            {[1, 1, 1, 1, 1].map((item, index) => {
              return (
                <img
                  src={index < reviewStars ? BlueStar : GreyStar}
                  className="review__item_rightsecstar"
                />
              );
            })}
          </div>
        </div>
      </div>
      {/* <div className="review__item_footer">
        <div className="review__item_footertxt">Was this Review Helpfull</div>
        <div className="review__item_footerthmb">
          <ThumbsUp
            className="review__item_footerthmbicon"
            fill={liked === "yes" ? "#0c69f3" : "#222222"}
            onClick={() => (liked === "yes" ? setLiked("") : setLiked("yes"))}
          />
          <span className="review__item_footerthmbcount">3</span>{" "}
        </div>
        <div className="review__item_footerthmb">
          <ThumbsDown
            className="review__item_footerthmbicon"
            fill={liked === "no" ? "#0c69f3" : "#222222"}
            onClick={() => (liked === "no" ? setLiked("") : setLiked("no"))}
          />
          <span className="review__item_footerthmbcount">2</span>{" "}
        </div>
      </div> */}
    </div>
  );
};

export default ReviewItem;
