import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import Basemap from "../../assets/images/basemap.png";
import Person from "../../assets/images/person.png";
import { ReactComponent as Marker } from "../../assets/icons/marker.svg";
import { ReactComponent as Envelope } from "../../assets/icons/envelope.svg";
import { ReactComponent as Envelop } from "../../assets/icons/envelop.svg";
import { ReactComponent as Phone } from "../../assets/icons/phone.svg";
import ReCAPTCHA from "react-google-recaptcha";
import { ReactComponent as Call } from "../../assets/icons/call.svg";
import { ReactComponent as Youtube } from "../../assets/icons/youtube.svg";
import { ReactComponent as Linkedin } from "../../assets/icons/linkedin.svg";
import { ReactComponent as Facebook } from "../../assets/icons/facebook.svg";
import { ReactComponent as Twitter } from "../../assets/icons/twitter.svg";
import { ReactComponent as Pinterest } from "../../assets/icons/pinterest.svg";
import { ReactComponent as User } from "../../assets/icons/user.svg";
import { ReactComponent as Building } from "../../assets/icons/building.svg";
import { ReactComponent as Comment } from "../../assets/icons/comment.svg";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import { ReactComponent as Next } from "../../assets/icons/next.svg";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
// import { useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import "./Contact.css";
import FeedbackCard from "../../Components/FeedbackCard/FeedbackCard";
import axios from "axios";
import { validateEmail } from "../../helpers/helpers";
import { Link } from "react-router-dom";

const TITLE = "Contact";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const initialData = {
  name: "",
  email: "",
  phone: "",
  company: "",
  question: "",
};
const Contact = () => {
  // const swiperr = useSwiper();
  const [slidesLength, setSlideLength] = useState(3);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [verified, setverified] = useState(false);
  const [swiperr, setSwiper] = useState();
  const [snack, setSnack] = useState({
    open: false,
    severity: "success",
    autoHideDuration: 6000,
    text: "",
    vertical: "top",
    horizontal: "right",
  });
  const [question, setQuestion] = useState(initialData);

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    setQuestion({ ...question, [name]: value });
  };

  const handleSendMessage = async () => {
    // console.log(question.email);
    // console.log(validateEmail(question.email));
    setSendingMessage(true);
    if (
      ![
        question.name,
        question.email,
        question.phone,
        question.question,
      ].includes("")
    ) {
      if (validateEmail(question.email)) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}landing-page/send-querry`,
            question
          );
          setSnack({
            ...snack,
            open: true,
            severity: "success",
            text: "Message sent successfully!",
          });
          setQuestion(initialData);
        } catch (error) {
          setSnack({
            ...snack,
            open: true,
            severity: "error",
            text: "Something went wrong!",
          });
        }
      } else {
        setSnack({
          ...snack,
          open: true,
          severity: "error",
          text: "Invalid Email address!",
        });
      }
    } else {
      setSnack({
        ...snack,
        open: true,
        severity: "error",
        text: "Please fill all required fields!",
      });
    }
    setSendingMessage(false);
  };
  function onChange(value) {
    setverified(value);
  }
  const handleRequest = async () => {
    // console.log(question.email);
    // console.log(validateEmail(question.email));
    setRequesting(true);
    if (
      ![
        question.name,
        question.email,
        question.phone,
        question.question,
      ].includes("")
    ) {
      if (validateEmail(question.email)) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}landing-page/send-querry`,
            question
          );
          setSnack({
            ...snack,
            open: true,
            severity: "success",
            text: "Message sent successfully!",
          });
          setQuestion(initialData);
        } catch (error) {
          setSnack({
            ...snack,
            open: true,
            severity: "error",
            text: "Something went wrong!",
          });
        }
      } else {
        setSnack({
          ...snack,
          open: true,
          severity: "error",
          text: "Invalid Email address!",
        });
      }
    } else if (!verified) {
      setSnack({
        ...snack,
        open: true,
        severity: "error",
        text: "Please fill the captcha first...",
      });
    } else {
      setSnack({
        ...snack,
        open: true,
        severity: "error",
        text: "Please fill all required fields!",
      });
    }
    setRequesting(false);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnack({ ...snack, open: false });
  };

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 2000) {
        setSlideLength(5);
      } else if (window.innerWidth > 1600) {
        setSlideLength(4);
      } else if (window.innerWidth > 1200) {
        setSlideLength(3);
      } else if (window.innerWidth > 900) {
        setSlideLength(2);
      } else {
        setSlideLength(1);
      }
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="contact__us_screen">
      <Helmet>
        <title>Hajex Bolt | {TITLE} </title>
        <meta name="theme-color" content="#000000" />
        <meta
          name="description"
          content="Simplify store integration with our free plugins for Woocommerce and Shopify - connect effortlessly in just a few clicks!,Track your packages/shipments using carrier provided tracking codes or use our unique tracking codes to ease and provide better tracking experience to your customers.,Our plugins come with 'Free Forever' plan to help Small Businesses."
        />
        <meta
          name="keywords"
          content="Custom Carrier Integration,Zero Commission - Seamless Integration, Carriers World Wide, Hajex Canada"
        />
        <meta name="author" content="Hajex" />
        <meta
          property="og:title"
          content="Your Powerful Ecommerce Shipping Solution"
        />
        <meta
          property="og:description"
          content="Simplify store integration with our free plugins for Woocommerce and Shopify - connect effortlessly in just a few clicks!,Track your packages/shipments using carrier provided tracking codes or use our unique tracking codes to ease and provide better tracking experience to your customers.,Our plugins come with 'Free Forever' plan to help Small Businesses."
        />
        <meta
          property="og:image"
          content="https://hajexbolt.com/static/media/storeconnectimg.8ae073090b0808c0fa50.png"
        />
        <meta property="og:url" content="https://hajexbolt.com/" />
        <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo192.png" />
      </Helmet>
      <Snackbar
        open={snack.open}
        autoHideDuration={snack.autoHideDuration}
        onClose={handleClose}
        anchorOrigin={{
          vertical: snack.vertical,
          horizontal: snack.horizontal,
        }}
      >
        <Alert
          onClose={handleClose}
          severity={snack.severity}
          sx={{ width: "100%" }}
        >
          {snack.text}
        </Alert>
      </Snackbar>
      <Header />
      {/* <img src={Basemap} alt="" className="contact__us_smap" /> */}
      <div className="contact__us_smap">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2797.1224006792263!2d-73.74413972307852!3d45.48747987107439!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc93d43fc3942b3%3A0xc7af12e3bc24c65f!2s5887%20Chemin%20St%20Fran%C3%A7ois%2C%20Saint-Laurent%2C%20QC%20H4S%201B6%2C%20Canada!5e0!3m2!1sen!2s!4v1715789085945!5m2!1sen!2s"
          frameBorder="0"
          style={{ height: "100%", width: "100%" }}
        />
      </div>
      <div className="contactus__content">
        <section className="contactus__content_contactsec">
          <div className="contactus__content_ctctsecleft">
            <div className="contactus__content_clheading">CONTACT US</div>
            <div className="contactus__content_clitem">
              <div className="contactus__content_clileft">
                <Marker />
              </div>
              <div className="contactus__content_cliright">
                5887 Chemin St François, Saint-Laurent, QC H4S1B6, Canada
              </div>
            </div>
            <div className="contactus__content_clitem">
              <div className="contactus__content_clileft">
                <Envelope />
              </div>
              <div className="contactus__content_cliright">
                info@hajexbolt.com
              </div>
            </div>
            <div className="contactus__content_clitem">
              <div className="contactus__content_clileft">
                <Phone />
              </div>
              <div className="contactus__content_cliright">855 999 6659</div>
            </div>
            <div className="contactus__content_clheading">FOLLOW US</div>
            <div className="contactus__content_icons">
              {/* <a className="contactus__content_icon">
                <Youtube />
              </a> */}
              <a
                className="contactus__content_icon"
                href="https://linkedin.com/company/hajex-bolt"
              >
                <Linkedin />
              </a>
              <a
                className="contactus__content_icon"
                href="https://www.facebook.com/people/HAJEX-Bolt/100088507484382/"
              >
                <Facebook />
              </a>
              {/* <a className="contactus__content_icon">
                <Twitter />
              </a>
              <a className="contactus__content_icon">
                <Pinterest />
              </a> */}
            </div>
          </div>
          <div className="contactus__content_ctctsecright">
            <div className="contactus__content_csrform">
              <div className="contactus__content_csrformrow">
                <div className="contactus__content_csrformfieldwrap">
                  <div className="contactus__content_csrformfieldicon">
                    <User />
                  </div>
                  <input
                    className="contactus__content_csrformfield"
                    type="text"
                    placeholder="Name*"
                    name="name"
                    value={question.name}
                    onChange={handleFieldChange}
                  />
                </div>
                <div className="contactus__content_csrformfieldwrap">
                  <div className="contactus__content_csrformfieldicon">
                    <Envelop />
                  </div>
                  <input
                    className="contactus__content_csrformfield"
                    type="email"
                    placeholder="Email*"
                    value={question.email}
                    name="email"
                    onChange={handleFieldChange}
                  />
                </div>
              </div>
              <div className="contactus__content_csrformrow">
                <div className="contactus__content_csrformfieldwrap">
                  <div className="contactus__content_csrformfieldicon">
                    <Call />
                  </div>
                  <input
                    className="contactus__content_csrformfield"
                    type="text"
                    placeholder="Phone Number*"
                    value={question.phone}
                    name="phone"
                    onChange={handleFieldChange}
                  />
                </div>
                <div className="contactus__content_csrformfieldwrap">
                  <div className="contactus__content_csrformfieldicon">
                    <Building />
                  </div>
                  <input
                    className="contactus__content_csrformfield"
                    type="text"
                    placeholder="Company Name"
                    value={question.company}
                    name="company"
                    onChange={handleFieldChange}
                  />
                </div>
              </div>
              <div className="contactus__content_csrformrow">
                <div className="contactus__content_csrformtafieldwrap">
                  <div className="contactus__content_csrformtxtfieldicon">
                    <Comment />
                  </div>
                  <textarea
                    className="contactus__content_csrformfield"
                    type="text"
                    placeholder="Question or Feedback"
                    name="question"
                    value={question.question}
                    onChange={handleFieldChange}
                  ></textarea>
                </div>
              </div>
              <div className="rebot d-flex pb-3">
                <ReCAPTCHA
                  sitekey="6Ld9BMcjAAAAAE-VKFPaecDHGEqeSKQdQ_GsZ-0W"
                  onChange={onChange}
                  size="normal"
                />
              </div>
              <div className="contactus__content_csrformrow">
                <div
                  className="contactus__content_csrformbtn"
                  onClick={handleRequest}
                >
                  {requesting ? (
                    <div style={{ color: "white" }}>
                      <CircularProgress
                        color="inherit"
                        style={{ width: "30px", height: "30px" }}
                      />
                    </div>
                  ) : (
                    "Request a call back"
                  )}
                </div>
                <div
                  className="contactus__content_csrformbtn"
                  onClick={handleSendMessage}
                >
                  {sendingMessage ? (
                    <div style={{ color: "white" }}>
                      <CircularProgress
                        color="inherit"
                        style={{ width: "30px", height: "30px" }}
                      />
                    </div>
                  ) : (
                    "Send Message"
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="contactus__feedback_section">
          <div className="contactus_fbsection_header">
            <div className="contactus_fbsection_headerleft">
              <div className="contactus_fbsection_headerltop">
                Our Customer Feedback
              </div>
              <div className="contactus_fbsection_headerlbottom">
                Don’t take our word for it. Trust our customers
              </div>
            </div>
            <div className="contactus_fbsection_headerright">
              <div
                className="fbsection_headerright_btn"
                onClick={() => swiperr.slidePrev()}
              >
                <span className="fbsection_headerright_btniconreverse">
                  <Next />
                </span>
                <span className="fbsection_headerright_btntext">Previous</span>
              </div>
              <div
                className="fbsection_headerright_btn"
                onClick={() => swiperr.slideNext()}
              >
                <span className="fbsection_headerright_btntext">Next</span>
                <span className="fbsection_headerright_btnicon">
                  <Next />
                </span>
              </div>
            </div>
          </div>
          <div className="contactus_fbsection_content">
            <Swiper
              spaceBetween={10}
              slidesPerView={slidesLength}
              // onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => setSwiper(swiper)}
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              pagination={{ clickable: true }}
            >
              <SwiperSlide>
                <FeedbackCard
                  person={Person}
                  name="Savannah Nguyen"
                  feedback="My orders and shipment are working flowless with this bolt system"
                  stars={5}
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </section> */}
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
