import React, { useEffect } from "react";
import GoogleMapReact from "google-map-react";
import Flag from "react-world-flags";
import "./Zoning.css";
import Countries, { countriesMap } from "../../../data/countries";

const Map = ({ countries }) => {
  const mapOptions = {
    styles: [
      {
        elementType: "geometry",
        stylers: [
          {
            color: "#f5f5f5",
          },
        ],
      },
      {
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161",
          },
        ],
      },
      {
        elementType: "labels.text.stroke",
        stylers: [
          {
            color: "#f5f5f5",
          },
        ],
      },
      {
        featureType: "administrative.land_parcel",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#bdbdbd",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "geometry",
        stylers: [
          {
            color: "#eeeeee",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "geometry",
        stylers: [
          {
            color: "#e5e5e5",
          },
        ],
      },
      {
        featureType: "poi.park",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [
          {
            color: "#ffffff",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#757575",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry",
        stylers: [
          {
            color: "#dadada",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#616161",
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#9e9e9e",
          },
        ],
      },
      {
        featureType: "transit.line",
        elementType: "geometry",
        stylers: [
          {
            color: "#e5e5e5",
          },
        ],
      },
      {
        featureType: "transit.station",
        elementType: "geometry",
        stylers: [
          {
            color: "#eeeeee",
          },
        ],
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [
          {
            color: "#c9c9c9",
          },
        ],
      },
    ],
    disableDefaultUI: true, // Disable the default UI controls
    draggable: true, // Disable dragging the map
    zoomControl: true, // Disable the zoom control
    scrollwheel: false, // Disable scrolling to zoom
    disableDoubleClickZoom: true, // Disable double-click zooming
  };

  const CustomMarker = ({ country, text, code }) => (
    <div className="carrzoning__custom_marker">
      <div className="carrzoning__custom_markerleft">
        <Flag
          code={code}
          fallback={<span>Unknown</span>}
          style={{ height: "100%", width: "100%" }}
        />{" "}
      </div>
      <div className="carrzoning__custom_markerright">
        {" "}
        <div className="carrzoning__custom_markercountry">{country}</div>
        <div className="carrzoning__custom_markertxt">{text}</div>
      </div>
    </div>
  );
  // const handleDownload = (data) => {
  //   const jsonData = JSON.stringify(data);
  //   const blob = new Blob([jsonData], { type: "application/json" });
  //   const url = URL.createObjectURL(blob);
  //   const link = document.createElement("a");
  //   link.download = "data.json";
  //   link.href = url;
  //   link.click();
  //   URL.revokeObjectURL(url);
  // };

  // useEffect(() => {
  //   const countryMap = {};
  //   for (const country of Countries) {
  //     countryMap[country.country] = {
  //       lat: country.latitude,
  //       lng: country.longitude,
  //       code: country.alpha3,
  //     };
  //   }
  //   handleDownload(countryMap);
  // }, []);

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: "AIzaSyC8NLz7EN8o0S_eZhwVDunyl0ut7o7vehI&libraries=drawing,places,geometry&v=weekly",
        }}
        defaultCenter={{
          lat: countriesMap[countries[0]?.name]?.lat,
          lng: countriesMap[countries[0]?.name]?.lng,
        }}
        defaultZoom={2}
        options={mapOptions}
      >
        {countries.map((country) => (
          <CustomMarker
            lat={country?.name && countriesMap[country?.name]?.lat}
            lng={country?.name && countriesMap[country?.name]?.lng}
            country={`${countriesMap[country?.name]?.name?.slice(0, 11)}${
              countriesMap[country?.name]?.name?.length > 11 ? "..." : ""
            }`}
            code={country?.name && countriesMap[country?.name]?.code}
            text={`${country?.services?.includes("pickup") ? "Pickup" : ""}${
              country?.services?.length > 1 ? " and " : ""
            }${country?.services?.includes("delivery") ? "Delivery" : ""}`}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};

export default Map;
