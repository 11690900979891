import image1 from "./images/icon1.png";
import image2 from "./images/icon2.png";
import image3 from "./images/icon3.png";
import "./FeatureBenefits.css";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const FeatureBenefits = () => {
    return(
        <section className="featurebenefits__main_wrap">
            <div className="featurebenefits__left_col">
                <div className="featurebenefits__left_col_firstcol">
                    <div className="featurebenefits__card">
                        <div className="featurebenefits__card_wrap">
                            <div className="featurebenefits__card_heading"><LazyLoadImage width="34" height="24" src={image1} alt="-"/><h3> Booked with <span>local carriers</span></h3></div>
                            <p>We are a door way to new carriers to help <span>minimize your costs</span> . We introduce new Carriers to give a better experience to both consignee and shipper.</p>
                        </div>
                    </div>
                    <div className="featurebenefits__card">
                        <div className="featurebenefits__card_wrap">
                            <div className="featurebenefits__card_heading"><LazyLoadImage width="33" height="24" src={image2} alt="-"/><h3> Enjoy <span>Insurance</span> on all Shipments</h3></div>
                            <p>We provide <span>Insurance on all Shipments</span> as offered by the carriers.</p>
                        </div>
                    </div>
                </div>
                <div className="featurebenefits__left_col_lastcol">
                    <div className="featurebenefits__card">
                        <div className="featurebenefits__card_wrap">
                            <div className="featurebenefits__card_heading"><LazyLoadImage width="33" height="24" src={image3} alt="-"/><h3> <span>Unbeatable</span> rates  </h3></div>
                            <p>0% comission. We pass you <span>100% of discount rate</span> with <span>100% tranparency</span>. </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="featurebenefits__right_col">
                <h2>Simplify Your Shipping Experience With <span>BOLT</span></h2>
                <p><span>BOLT</span> offers a comprehensive solution designed to simplify shipping for merchants like you. With <span>BOLT</span>, you not only get an easy-to-use shipping platform but also access to helpful support whenever you need it. But that's not all – <span>BOLT</span> provides a range of additional features and benefits to enhance your shipping experience and streamline your operations.</p>
            </div>
        </section>
    )
}
export default FeatureBenefits;