import "./CustomCarrier.css";
import rightmascot from "./images/customcarrier.png";
import Carrier from "./images/customcarrier.jpg";
import checkicon from "./images/check.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const CustomCarrier = () => {
  return (
    <section className="customcarrier__section_wrap">
      <div className="customcarrier__wrap">
        <div className="customcarrier__left_col">
          <LazyLoadImage src={Carrier} alt="-" />
        </div>
        <div className="customcarrier__right_col">
          <h2 className="customcarrier__heading">
            Simplified Carrier Management
          </h2>
          <p className="customcarrier__bodycopy">
            Say goodbye to managing multiple carrier accounts and juggling
            different systems. Our software consolidates all your carrier
            integrations into one user-friendly platform, saving you time and
            eliminating the complexity of dealing with various shipping
            providers.
          </p>
        </div>
      </div>
    </section>
  );
};
export default CustomCarrier;
