import React, { useEffect } from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import "./PrivacyPolicy.css";
const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="privacy__policy_screen">
      <Header />
      <div className="privacy__policy_content">
        <h1 className="terms__conds_heading">Accepting website terms of use</h1>
        <p className="privacy__policy_desc">
          In order to use hajexbolt.com, the terms and conditions constitute a
          legal agreement and are entered into by and between you and
          (hajexbolt).
          <span className="privacy__policy_descbold">
            ("Company," "we," "us," "our")
          </span>
          . The following terms and conditions, together with any documents
          and/or additional terms they expressly incorporate by reference
          (collectively, these{" "}
          <span className="privacy__policy_descbold">"Terms of Use"</span>),
          govern your access to and use, including any content, functionality,
          and services offered on or through (website), and its subdomains, or
          such other URL as the Company may notify you about from time to time
          (the <span className="privacy__policy_descbold">"Website"</span>).
        </p>
        <p className="privacy__policy_desc">
          By clicking to accept the terms and conditions and accepting to use
          the website, you agree to comply and to be bound by these terms of
          use. If you disagree to these terms of use or the privacy policy, you
          must not use the website. When you are utilizing this Website, you
          embody and permit that you are the lawful age of the common majority
          under applicable law to form a binding contract with the Company and
          meet all of the foregoing eligibility requirements. If you do not meet
          all of these requirements, you must not access or use the Website. We
          reserve the right, at our sole discretion, to change or modify
          portions of this TOS at any time.
        </p>
        <p className="privacy__policy_desc">
          Hajexbolt might ask you to register in order to access and use certain
          features of the Service. If you agree to register for the Service, you
          agree to give and keep true, correct, existing, and comprehensive
          evidence about yourself as prompted by the registration form of the
          service. You agree and recognize that if you use certain features of
          the Services, you are required to provide current and accurate
          information about yourself, which includes your contact number as well
          as your address. If you fail to provide correct, accurate, complete or
          up-to-date information, Hajexbolt is not accountable for any delays or
          inabilities from the Services.
        </p>
        <h1 className="terms__conds_heading">
          1. Modifying website terms and conditions
        </h1>
        <p className="privacy__policy_desc">
          We reserve the right in our sole discretion to revise and update these
          terms and conditions from time to time. Any and all such modifications
          are effective immediately upon posting and apply to all access to and
          continued use of the Website. You agree to periodically review the
          terms and conditions in order to be aware of any such modifications
          and your continued use shall be your acceptance of these Terms of Use.
        </p>
        <h1 className="terms__conds_heading">2. No dependency</h1>
        <p className="privacy__policy_desc">
          (a) The blogs/content/information on our Website (Hajexbolt) is issued
          for general information purposes only. The information does not intend
          to mount to advice on which you should trust. Prior to taking any
          action or refraining from any inaction on the basis of the content on
          our site, you must first get full specific, or professional advice.
        </p>
        <p className="privacy__policy_desc">
          (b) Even though we make realistic efforts to bring up-to-date
          information on our Website (Hajexbolt), we make no representations,
          warranties, or guarantees, whether prompt or implied, that the
          content/blogs/info on our website is exact, comprehensive, or up to
          date.
        </p>
        <h1 className="terms__conds_heading">
          3. Responsibility for loss or damage
        </h1>
        <p className="privacy__policy_desc">
          (b) Even though we make realistic efforts to bring up-to-date
          information on our Website (Hajexbolt), we make no representations,
          warranties, or guarantees, whether prompt or implied, that the
          content/blogs/info on our website is exact, comprehensive, or up to
          date.
        </p>
        <p className="privacy__policy_desc">
          The limit of liability for damage or loss in each shipment is
          restricted to a value of{" "}
          <span className="privacy__policy_highlight">$100</span>. Remember that
          damage is at the sole discretion of Hajexbolt. Unless you purchase a
          specific level of insurance through the Hajexbolt website during the
          process of checkout, you consent to release the value of each shipment
          which is no more than{" "}
          <span className="privacy__policy_highlight">$100</span>. This value is
          quite reasonable under the circumstances covering the transportation,
          and Hajexbolt shall not be accountable for more than{" "}
          <span className="privacy__policy_highlight">$100</span> for each
          shipment. Your loss reimbursement can be done by Hajexbolt if the
          damage is above{" "}
          <span className="privacy__policy_highlight">$100</span>, and if you
          declare a value of an additional{" "}
          <span className="privacy__policy_highlight">$100</span> for the
          matching amount on the Hajexbolt website. If you assert an advanced
          value for a shipment, you might need to pay the supplementary charge
          at the rates set forth in the Services at the time of confirmation of
          a shipment as or if approved by the carrier.
        </p>
        <h1 className="terms__conds_heading">4. Shipment acceptance policy</h1>
        <p className="privacy__policy_desc">
          The Interior (internal) packaging system must firmly and strongly hold
          the contents of a Shipment in place. The article must be surrounded
          firmly by interior packaging and avoid movement within the carton in
          order to protect the contents of the carton from normal external
          forces. These might include pressure exerted by piling other Shipments
          on top of it. For lightweight and/or fragile Shipments, adequate
          internal packaging is specifically important. Shipments not wrapped
          properly in accordance with Hajexbolt shipment acceptance policy will
          travel at the risk of the Shipper.
        </p>
        <h1 className="terms__conds_heading">5. Errors and Omissions</h1>
        <p className="privacy__policy_desc">
          Please keep in mind that there might be typographical errors or
          inaccuracies on the Website (Hajexbolt). It may or may not be complete
          or current. We reserve the right to correct any errors, inaccuracies,
          or omissions and to change or update information at any time, without
          prior notice (including after an order has been submitted). Such
          errors, inaccuracies, or omissions may relate to product description,
          pricing, promotion, and availability and we reserve the right to
          cancel or refuse any order placed based on incorrect pricing or
          availability information, to the extent permitted by applicable law.
          We do not undertake to update, modify or clarify information on our
          website, except as required by law.
        </p>
        <h1 className="terms__conds_heading">6. Termination</h1>
        <p className="privacy__policy_desc">
          If you want to terminate your account, you can do this anytime by
          sending a cancellation request to info@hajexbolt.com. Subject to
          earlier termination as provided below, Hajexbolt may terminate your
          Account and this TOS at any time. Hajexbolt might provide you{" "}
          <span className="privacy__policy_highlight">thirty (30) days</span>{" "}
          prior notice to the administrative email address which is associated
          with your account. If you break any of the terms or conditions of this
          TOS, Hajexbolt may also end this TOS upon{" "}
          <span className="privacy__policy_highlight">thirty (30) days</span>’
          notice (
          <span className="privacy__policy_highlight">or ten (10) days</span> in
          the case of nonpayment). Plus, Hajexbolt may terminate this TOS
          instantly without notice if you disrupt any provision of the
          Hajexbolt. Hajexbolt reserves the right to alter or discontinue,
          temporarily or permanently, the Service (or any part thereof).
        </p>
        <h1 className="terms__conds_heading">7. Disclaimer</h1>
        <p className="privacy__policy_desc">
          The use of logos belonging to carriers and vendors on this website
          does not imply endorsement, affiliation, or partnership with HAJEX
          Bolt. These logos are used for informational purposes only to indicate
          the carriers and vendors with whom we may interact, but we are not
          responsible for their services or products. HAJEX Bolt is not
          responsible for the accuracy, reliability, or quality of services
          provided by these carriers or vendors. Any issues, disputes, or
          inquiries regarding their services or products should be directed to
          the respective carriers or vendors.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
