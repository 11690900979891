import Snackbars from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import React from "react";

export default function Snackbar(props) {
  const { Snack, SetSnack } = props;

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleSnackClose = () => {
    SetSnack({ ...Snack, open: false });
  };

  // import Snackbar from "../../../Customer/MainPages/Components/snackbar";  { Path }

  // const [snack, setSnack] = useState({  {Declare snack}
  //   open: false,
  //   vertical: "top",
  //   horizontal: "right",
  //   text: "",
  //   severity: "",
  // });

  // setSnack({   {setsnack}
  //   ...snack,
  //   open: true,
  //   text: response.data.message,
  //   severity: "error / success",
  // });

  // <Snackbar Snack={snack} SetSnack={setSnack} />;     {Call Snack}

  return (
    <>
      {Snack && (
        <Snackbars
          open={Snack.open}
          autoHideDuration={3000}
          onClose={handleSnackClose}
          anchorOrigin={{
            vertical: Snack.vertical,
            horizontal: Snack.horizontal,
          }}
        >
          <Alert
            onClose={handleSnackClose}
            severity={Snack.severity}
            sx={{ width: "100%" }}
          >
            {Snack.text}
          </Alert>
        </Snackbars>
      )}
    </>
  );
}
