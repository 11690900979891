import "./ZeroCommission.css";
import rightmascot from "./images/zerocommision.png";
import checkicon from "./images/check.png";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ZeroCommission = () => {
return(
    <section className="zerocommission__section_wrap" id="zerocommission__section_wrap">
        <div className="zerocommission__wrap">
            <div className="zerocommission__left_col">
                <h2 className="zerocommission__heading">Zero Commission,
    Seamless Integration</h2>
                <p className="zerocommission__bodycopy">0% Commission Booking Through Our Carrier Accounts or
    Seamless Integration of Your Own Carrier at No Fee!</p>
                <ul className="zerocommission__list_wrap">
                    <li className="zerocommission__list"><LazyLoadImage src={checkicon} alt="-" />Seamless Carrier Account Integration: No Fees for Canpar, UPS, Canada Post, Nationex, FedEx, and More</li>
                    <li className="zerocommission__list"><LazyLoadImage src={checkicon} alt="-" />Utilize Our Carrier Accounts with 0% Commission!</li>
                    <li className="zerocommission__list"><LazyLoadImage src={checkicon} alt="-" />Display Carrier Rates on Your Woocommerce or Shopify Stores!</li>
                </ul>
            </div>
            <div className="zerocommission__right_col">
                <LazyLoadImage src={rightmascot} alt="-" />
            </div>
        </div>
    </section>
)
}
export default ZeroCommission;