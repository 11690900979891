import "./PluginWorks.css";
import orderbox from "./images/order-box.png";
import boltIcon from "./images/boltIcon.png";
import deliverybox from "./images/delivery-box.png";
import emailicon from "./images/emailicon.png";
import deliverylocation from "./images/delivery-location.png";
import feedback from "./images/feedback.png";
import { LazyLoadImage } from 'react-lazy-load-image-component';


const PluginWorks = () => {
    return (
      <>
       <section className="integration__pluginworks_wrap">
        <h2 className="integration__pluginworks_heading">How BOLT integration plugin works</h2>
      <ul className="integration__pluginworks_listing">
          <li>
            <div className="integration__pluginworks_listing_img"><LazyLoadImage width="38" height="38"  src={orderbox} alt="vector" /></div>
            <h2>Order Received</h2>
            <p>Order placed by customer on your shopify, woocommerce, amazon, bestbuy, walmart, ebay or other store</p>
          </li>
          <li>
          <div className="integration__pluginworks_listing_img"><LazyLoadImage width="47" height="49"  src={boltIcon} alt="Artboard34x" /></div>
            <h2>Received By BOLT</h2>
            <p>Order Fetched by BOLT gets its own in dashboard easy management</p>
          </li>
          <li>
          <div className="integration__pluginworks_listing_img"><LazyLoadImage width="47" height="46"  src={deliverybox} alt="vector6" /></div>
            <h2>Shipping Carrier</h2>
            <p>Easy booking with Shipping carriers with no commission. Click Ship Now to auto populate order shipper and consignee information.</p>
          </li>
          <li>
          <div className="integration__pluginworks_listing_img"><LazyLoadImage width="47" height="47"  src={emailicon} alt="email11" /></div>
            <h2>Email Tracking</h2>
            <p>Upon booking with BOLT both shipper and consignee get branded emails with all necessary information to track package</p>
          </li>
          <li>
          <div className="integration__pluginworks_listing_img"><LazyLoadImage width="47" height="47"  src={deliverylocation} alt="deliverybox1" /></div>
            <h2>Live Tracking</h2>
            <p>Tracking code can be used on our website or Google to easily tracking all orders by shipper and consignee with Live Tracking coming soon.</p>
          </li>
          <li>
          <div className="integration__pluginworks_listing_img"><LazyLoadImage width="42" height="42"  src={feedback} alt="delivery1" /></div>
            <h2>Happy Customers</h2>
            <p>Once delivery is complete, shipper and consignee will get email from BOLT to review the shipping and delivery experience.</p>
          </li>
      </ul>
    </section>
      </>
   
);
  };
  
  export default PluginWorks;